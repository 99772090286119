import React, { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useAtom } from 'jotai';
import classes from './ClientUploads.module.css';
import {
    Row,
    Col,
    Button,
    Table,
    Space,
    Descriptions,
    Select,
    Input,
    AutoComplete,
    Modal,
    message,
    Tag,
    DatePicker,
    Form,
    Radio,
    Badge,
    Tooltip,
    Avatar,
    Skeleton,
    Empty,
    Switch,
    Typography
} from 'antd';
import {
    WarningTwoTone,
    ExclamationCircleOutlined,
    SearchOutlined,
    FilterOutlined,
    UndoOutlined,
    ExportOutlined,
    InboxOutlined
} from '@ant-design/icons';
import {
    fetchAllScanData,
    fetchArchivedScans,
    fetchClientCodes,
    updateRecord,
    createRecord,
    saveClientData,
    deleteClient,
    getExpectedSubstances,
    searchMassSpecData,
    saveMassSpecRow,
    fetchOperators,
    archiveScan,
    fetchArchivedResults,
    restoreScan
} from '../utils/dbHelpers.js';
import {
    scanningState,
} from '../stateManagement/processState';
import {
    allScanDataState,
    deviceInfoState,
    userNameState,
    dateRangeState,
    clientCodesState,
    defaultDateRange,
    isImageModalOpenState,
    isNewRecordCreatedState,
    imageFilenameState,
    imageInfoSerialState,
    expandImageInfoSerialRowTrigger,
    isTestInfoModalOpenState,
    massSpectrometryTableDataState,
    isAdminState,
    riskAwarenessShouldUpdateState,
    operatorsState,
    operatorsMapState,
    currentOperatorState,
    expectedSubstancesState,
    currentUserState,
    showArchivedScansState
} from '../stateManagement/commonState';
import { isUUID } from '../utils/helpers';
import dayjs from 'dayjs';
import ISO6391 from 'iso-639-1';
import { cloneDeep, throttle } from 'lodash';
import ImageModal from './ImageModal';
import VisualResultsTable from './VisualResultsTable.js';
import { fetchResults, fetchSpectrum, uploadResults } from '../utils/dbHelpers';
import { classifySpectrum, detectTraceFentanyl, detectTraceHydromorphone, detectTraceBenzodiazepine, detectTraceXylazine } from '../utils/detect.js';
import { substanceArray, substanceMap, substanceThresholds } from '../utils/substancesAndColors.js';
import { computeResults } from '../utils/results.js';
import TestInfoModal from './TestInfoModal';

const languages = ISO6391.getLanguages(ISO6391.getAllCodes()).map(e => ({ value: e.code, label: e.name }));

const { Option } = Select;
const { TextArea } = Input;
const { confirm } = Modal;
const { RangePicker } = DatePicker;
const { Title } = Typography;

const key = 'updatable';

let searchInput = '';

let clientUploadsWorker = null;

function ClientUploads({ mapViewButtonHandler, setCurrentSerial, setCurrentMapID, map, traceMap, setClientUploadsWorkerInitialized }) {
    const [allScanData, setAllScanData] = useAtom(allScanDataState);
    const [archivedScans, setArchivedScans] = useState([]);
    const [scanning] = useAtom(scanningState);
    const [deviceInfo] = useAtom(deviceInfoState);
    const [username] = useAtom(userNameState);
    const [dataLoading, setDataLoading] = useState(false);
    const participantProfileFormRef = useRef(null);
    const [showAdditionalFilters, setShowAdditionalFilters] = useState(false);
    const [scanDetectedFilterMethod, setScanDetectedFilterMethod] = useState('AND');
    const [selectedSubstancesForResultFilter, setSelectedSubstancesForResultFilter] = useState([]);
    const [resultsFilterIsIncusive, setResultsFilterIsIncusive] = useState(true);
    const [detectedSubstances, setDetectedSubstances] = useState([]);
    const [, setIsImageModalOpen] = useAtom(isImageModalOpenState);
    const [, setIsNewRecordCreated] = useAtom(isNewRecordCreatedState);
    const [, setImageFilename] = useAtom(imageFilenameState);
    const [expectedSubstances, setExpectedSubstances] = useAtom(expectedSubstancesState);
    const [imageInfoSerial, setImageInfoSerial] = useAtom(imageInfoSerialState);
    const [expandImageInfoSerialRow] = useAtom(expandImageInfoSerialRowTrigger);
    const [isTestInfoModalOpen, setIsTestInfoModalOpen] = useAtom(isTestInfoModalOpenState);
    const [labSampleIDMatches, setLabSampleIDMatches] = useState([]);
    const [massSpectrometryTableData, setMassSpectrometryTableData] = useAtom(massSpectrometryTableDataState);
    const [isAdmin] = useAtom(isAdminState);
    const [recordLabSampleData, setRecordLabSampleData] = useState(null);
    const [, setRiskAwarenessShouldUpdate] = useAtom(riskAwarenessShouldUpdateState);
    const [operators, setOperators] = useAtom(operatorsState);
    const [operatorsMap, setOperatorsMap] = useAtom(operatorsMapState);
    const [currentOperator, setCurrentOperator] = useAtom(currentOperatorState);
    const [currentUser, setCurrentUser] = useAtom(currentUserState);
    const [loadingResults, setLoadingResults] = useState(false);
    const [expandedRows, setExpandedRows] = useState([]);
    const [clientCodes, setClientCodes] = useAtom(clientCodesState);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [dateRange, setDateRange] = useAtom(dateRangeState);
    const currentSerialNumber = React.useRef(null);
    const [clientUploadsWorkerResults, setClientUploadsWorkerResults] = useState({});
    const [showArchivedScans, setShowArchivedScans] = useAtom(showArchivedScansState);
    const [currentPage, setCurrentPage] = useState(1);

    const clientUploadsWorkerMessageHandler = async (e) => {
        if (e.data.type === 'PREDICTION_RESULT') {
            const serial = e.data.serial;
            const mapID = e.data.mapID;
            let mapFromDB = e.data.data;
            mapFromDB = mapFromDB.map(e => {
                const label = classifySpectrum(e.processed_spectrum, e.result, e.reconstructionError, e.raw_spectrum, e.benzodiazepinePrediction)
                if (e.result[0][substanceMap.get(label)] < substanceThresholds[label]) {
                    e.result[0][substanceMap.get(label)] = substanceThresholds[label] + 0.001;
                }
                return { ...e, label: classifySpectrum(e.processed_spectrum, e.result, e.reconstructionError, e.raw_spectrum, e.benzodiazepinePrediction) }
            });
            mapFromDB = mapFromDB.map(e => { return { ...e, mlOutput: e.result[0].map((f, i) => [substanceArray[i], f, e.result[1][i], e.result[2][i]]).sort((a, b) => b[1] - a[1]).map(g => [g[0], Math.round(g[1] * 1000) / 1000, Math.round(g[2] * 1000) / 1000, Math.round(g[3] * 1000) / 1000]) } });
            mapFromDB = mapFromDB.map((e) => {
                return ({
                    index: e.index,
                    x: e.coordinate[0],
                    y: e.coordinate[1],
                    z: e.coordinate[2],
                    power: e.laser_power,
                    integrationTime: e.integration_time,
                    rawSpectrum: e.raw_spectrum,
                    processedSpectrum: e.processed_spectrum,
                    normalizedSpectrum: e.normalizedSpectrum,
                    result: e.result,
                    label: e.label,
                    mlOutput: e.mlOutput,
                    reconstructionError: e.reconstructionError,
                    fentanylPrediction: e.fentanylPrediction,
                    xylazinePrediction: e.xylazinePrediction,
                    benzodiazepinePrediction: e.benzodiazepinePrediction,
                    hydromorphonePrediction: e.hydromorphonePrediction,
                })
            });

            let traceMapFromDB = [];

            for (let i = 0; i < mapFromDB.length; i++) {
                let z = mapFromDB[i].z;
                const { label, processedSpectrum, result, mlOutput, fentanylPrediction, xylazinePrediction, benzodiazepinePrediction, hydromorphonePrediction } = mapFromDB[i];

                let { hasTraceFentanyl, traceFentanylLabel } = detectTraceFentanyl(label, processedSpectrum, result, fentanylPrediction, mlOutput);
                let { hasTraceXylazine, traceXylazineLabel } = detectTraceXylazine(label, processedSpectrum, result, mlOutput, xylazinePrediction);
                let hasTraceHydromorphone = detectTraceHydromorphone(label, processedSpectrum, result, hydromorphonePrediction);
                let { hasTraceBenzodiazepine, traceBenzodiazepineLabel } = detectTraceBenzodiazepine(label, processedSpectrum, result, benzodiazepinePrediction, mlOutput);

                if (hasTraceFentanyl) { z += 0.1; traceMapFromDB.push({ ...mapFromDB[i], z: z, label: traceFentanylLabel }); }
                if (hasTraceHydromorphone) { z += 0.1; traceMapFromDB.push({ ...mapFromDB[i], z: z, label: 'Hydromorphone' }); }
                if (hasTraceBenzodiazepine) { z += 0.1; traceMapFromDB.push({ ...mapFromDB[i], z: z, label: traceBenzodiazepineLabel }); }
                if (hasTraceXylazine) { z += 0.1; traceMapFromDB.push({ ...mapFromDB[i], z: z, label: traceXylazineLabel }); }
                if (!hasTraceFentanyl && !hasTraceHydromorphone && !hasTraceBenzodiazepine && !hasTraceXylazine) { traceMapFromDB.push({}); }
            }

            const results = computeResults(mapFromDB, traceMapFromDB);

            setClientUploadsWorkerResults({ serial, mapID, results });

        }
        else if (e.data.type === 'MODEL_INITIALIZED') {
            console.log('clientUploadsWorker initialized.');
            setClientUploadsWorkerInitialized(true);
        }
    }

    useEffect(() => {
        const { serial, mapID, results } = clientUploadsWorkerResults;
        if (serial && mapID && results) {
            (async () => {
                let scanIndex = allScanData.findIndex(e => e.key == serial);
                if (scanIndex !== -1) {
                    setAllScanData(prevAllScanData => {
                        let newAllScanData = [...prevAllScanData];
                        newAllScanData[scanIndex] = { ...newAllScanData[scanIndex], results };
                        return newAllScanData;
                    });
                }
                await uploadResults({ mapID, results });
                setLoadingResults(false);
            })();
        }
    }, [clientUploadsWorkerResults]);

    useEffect(() => {
        if (scanning && allScanData.length && map.length && traceMap.length) {
            const results = computeResults(map, traceMap);
            setAllScanData(prevAllScanData => {
                let newAllScanData = [...prevAllScanData];
                newAllScanData[0] = { ...newAllScanData[0], results };
                return newAllScanData;
            });
        }
    }, [scanning, map, traceMap]);

    useEffect(() => {
        clientUploadsWorker = new Worker(new URL('../tensorflowWorker.js', import.meta.url));
        clientUploadsWorker.onmessage = e => clientUploadsWorkerMessageHandler(e);
        clientUploadsWorker.postMessage({ type: 'INIT_MODELS' });
    }, []);

    useEffect(() => {
        if (imageInfoSerial) {
            setFilteredInfo({ serial: [`${imageInfoSerial}`] });
            if (expandedRows[0]) { autoSaveRecord(expandedRows[0]) };
            setExpandedRows([imageInfoSerial]);
        }
    }, [expandImageInfoSerialRow]);

    const confirmRecordArchive = (record) => {
        confirm({
            width: 600,
            title: 'Do you want to archive this record?',
            icon: <ExclamationCircleOutlined />,
            content: (
                <div>
                    <br />
                    <table style={{ fontWeight: 'normal' }}>
                        <tbody>
                            <tr><td style={{ padding: '0 10px' }}>{'Serial No.'}</td><td>{record.serial}</td></tr>
                            <tr><td style={{ padding: '0 10px' }}>{'Client Code'}</td><td>{record.client_code ? record.client_code : 'N/A'}</td></tr>
                            <tr><td style={{ padding: '0 10px' }}>{'Date'}</td><td>{dayjs(record.created_at).format('MMM D, YYYY')}</td></tr>
                            <tr><td style={{ padding: '0 10px' }}>{'Expected Substances'}</td><td>{record.expected_substances?.join(', ')}</td></tr>
                            <tr><td style={{ padding: '0 10px' }}>{'Description'}</td><td>{record.substance_description}</td></tr>
                            <tr><td style={{ padding: '0 10px' }}>{'Results'}</td><td>{record.label_names?.filter(e => e !== 'Aluminium').join(', ')}</td></tr>
                            <tr><td style={{ padding: '0 10px' }}>{'Scan Impact'}</td><td>{record.impact}</td></tr>
                            <tr><td style={{ padding: '0 10px' }}>{'Strength'}</td><td>{record.substance_strength}</td></tr>
                            <tr><td style={{ padding: '0 10px' }}>{'Notes'}</td><td>{record.notes}</td></tr>
                        </tbody>
                    </table>
                </div>
            ),
            onOk() {
                setExpandedRows([]);
                archiveScan(record.map_id).then(response => {
                    updateMapTable();
                    if (response.success) {
                        response.message === 'Scan not found.' ? message.info(response.message, 6) : message.success(response.message, 6);
                    } else {
                        message.error('Archive error.', 6);
                    }
                }).catch(error => {
                    console.error('Scan delete error! ', error);
                    message.error('Failed to archive scan.', 6);
                });
            }
        });
    }

    const handleRestoreScan = async record => {
        setExpandedRows([]);
        try {
            const res = await restoreScan(record.map_id);
            setArchivedScans(prevArchivedScans => prevArchivedScans.filter(e => e.map_id !== record.map_id));
            setAllScanData(prevAllScanData => [record, ...prevAllScanData].sort((a, b) => b.serial - a.serial));
            message.success(`Scan ${record.serial} restored.`, 6);
        } catch {
            message.error(`Failed to restore scan ${record?.serial}.`, 6);
        }
    }

    const confirmRestore = async (record) => {
        confirm({
            width: 600,
            title: 'Do you want to restore this record?',
            icon: <ExclamationCircleOutlined />,
            content: (
                <div>
                    <br />
                    <table style={{ fontWeight: 'normal' }}>
                        <tbody>
                            <tr><td style={{ padding: '0 10px' }}>{'Serial No.'}</td><td>{record.serial}</td></tr>
                            <tr><td style={{ padding: '0 10px' }}>{'Client Code'}</td><td>{record.client_code ? record.client_code : 'N/A'}</td></tr>
                            <tr><td style={{ padding: '0 10px' }}>{'Date'}</td><td>{dayjs(record.created_at).format('MMM D, YYYY')}</td></tr>
                            <tr><td style={{ padding: '0 10px' }}>{'Expected Substances'}</td><td>{record.expected_substances?.join(', ')}</td></tr>
                            <tr><td style={{ padding: '0 10px' }}>{'Description'}</td><td>{record.substance_description}</td></tr>
                            <tr><td style={{ padding: '0 10px' }}>{'Results'}</td><td>{record.label_names?.filter(e => e !== 'Aluminium').join(', ')}</td></tr>
                            <tr><td style={{ padding: '0 10px' }}>{'Scan Impact'}</td><td>{record.impact}</td></tr>
                            <tr><td style={{ padding: '0 10px' }}>{'Strength'}</td><td>{record.substance_strength}</td></tr>
                            <tr><td style={{ padding: '0 10px' }}>{'Notes'}</td><td>{record.notes}</td></tr>
                        </tbody>
                    </table>
                </div>
            ),
            onOk() { handleRestoreScan(record) }
        });
    }

    useEffect(() => {
        if (scanning) {
            setDateRange(defaultDateRange);
            handleStartScan();
            setFilteredInfo({});
            setSelectedSubstancesForResultFilter([]);
            setResultsFilterIsIncusive(true);
            setScanDetectedFilterMethod('AND');
            setShowAdditionalFilters(false);
        }
    }, [scanning]);

    const handleStartScan = async () => {
        try {
            setShowArchivedScans(false);
            setCurrentPage(1);
            if (expandedRows[0]) { autoSaveRecord(expandedRows[0]) };
            const { serial, map_id } = await createRecord({
                username,
                device_id: deviceInfo.device_id,
                operator_id: currentOperator
            }, message, key);
            currentSerialNumber.current = serial;
            const imageFilename = (deviceInfo.device_camera_status && deviceInfo.device_network_status) ? `${serial}_${new Date().toISOString().replace(/[:T\-]/g, '').slice(0, -5)}_${username}_${deviceInfo?.device_firmware_version}.jpg` : null;
            setImageFilename(imageFilename);
            setCurrentSerial(serial);
            setIsNewRecordCreated(true);
            setCurrentMapID(map_id);
            let newRow = {
                "key": serial,
                "serial": serial,
                "map_id": map_id,
                "client_code": null,
                "expected_substances": null,
                "substance_strength": null,
                "substance_description": null,
                "impact": null,
                "notes": "",
                "device_id": deviceInfo.device_id,
                "results": null,
                "gender": null,
                "age": null,
                "chronic_pain": null,
                "indigenous": null,
                "brpoc": null,
                "lgbtq": null,
                "preferred_language": null,
                "username": username,
                "image_filename": imageFilename,
                "operator": null,
                "operator_id": currentOperator,
                "testing_for": null,
                "consumed": null,
                "date": dayjs().format('MMM D, YYYY'),
                "showTrace": true
            }
            if (imageFilename) { await updateRecord(newRow); }
            let newAllScanData = cloneDeep(allScanData);
            setAllScanData([newRow, ...newAllScanData]);
            setCurrentOperator(null);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchExpectedSubstances();
    }, []);

    useEffect(() => {
        setDataLoading(false);
        const substanceFrequencyMap = {}
        for (let i = 0; i < allScanData.length; i++) {
            if (!allScanData[i].results?.length) continue;
            // Object.keys(allScanData[i].results).forEach(substance => {
            allScanData[i].results.map(row => row.substance).forEach(substance => {
                if (substanceFrequencyMap[substance]) {
                    substanceFrequencyMap[substance]++;
                } else {
                    substanceFrequencyMap[substance] = 1;
                }
            });
        }
        const sortedSubstanceFrequencyMap = Object.entries(substanceFrequencyMap).sort((a, b) => b[1] - a[1]);
        setDetectedSubstances(sortedSubstanceFrequencyMap);
        if (scanning) {
            setExpandedRows([currentSerialNumber.current]);
        } else {
            currentSerialNumber.current = null;
        }
        if (expandedRows.length) {
            fetchRecordLabSampleData(allScanData.find(s => s.serial === expandedRows[0])?.lab_sample_id);
        }
    }, [allScanData]);

    const fetchExpectedSubstances = async () => {
        const expectedSubstances = await getExpectedSubstances();
        setExpectedSubstances(expectedSubstances);
    }

    const updateMapTable = async () => {
        if (username && dateRange) {
            setDataLoading(true);
            try {
                let scanData = (await fetchAllScanData({ username, dateRange })).map((map, index) => {
                    if (!map.operator_id) { map.operator_id = map.operator; } // For backwards compatibility. Phase out eventually.
                    return { key: map.serial, ...map, date: dayjs(map.created_at).format('MMM D, YYYY'), showTrace: true };
                });
                setAllScanData(scanData);
                setRiskAwarenessShouldUpdate(true);
            } catch (error) {
                setAllScanData([]);
            }
        }
    }

    const updateArchiveTable = async () => {
        if (username && dateRange) {
            setDataLoading(true);
            try {
                const archivedScansData = (await fetchArchivedScans({ username, dateRange })).map((map, index) => {
                    if (!map.operator_id) { map.operator_id = map.operator; } // For backwards compatibility. Phase out eventually.
                    return { key: map.serial, ...map, date: dayjs(map.created_at).format('MMM D, YYYY'), showTrace: true };
                });
                setArchivedScans(archivedScansData);
            } catch (error) {
                message.error('Failed to fetch archived scans.', 6);
            } finally {
                setDataLoading(false);
            }
        }
    }

    useEffect(() => {
        if (showArchivedScans) {
            updateArchiveTable();
        }
    }, [showArchivedScans]);

    useEffect(() => {
        if (username) {
            updateClientCodes();
            updateMapTable();
            loadOperators();
        }
    }, [username]);

    useEffect(() => {
        setExpandedRows([]);
        updateMapTable();
        if (showArchivedScans) { updateArchiveTable(); }
    }, [dateRange]);

    const loadOperators = async () => {
        const res = await fetchOperators(username);
        if (res.success) {
            setOperators(res.data);
            setOperatorsMap((res.data || []).reduce((acc, operator) => {
                acc[operator.operator_id] = operator;
                return acc;
            }, {}));
        }
    };

    const updateClientCodes = async () => {
        fetchClientCodes(username).then((clientCodes) => {
            setClientCodes(clientCodes);
        });
    }

    const handleRecordChange = (row) => {
        const newData = cloneDeep(allScanData);
        const index = newData.findIndex(item => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        setAllScanData(newData);
    };

    const getColumnSearchProps = (dataIndex, alias) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${alias}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => { handleReset(clearFilters) }} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownOpenChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.select(), 100);
            }
        },
    });

    const clientUploadColumns = [
        ...(username === 'admin'
            ? [
                {
                    title: 'Username',
                    dataIndex: 'username',
                    key: 'username',
                    width: 125,
                    align: 'center',
                    render: text => (text ? (<div style={{
                        width: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}>{text}</div>) : null),
                    ...getColumnSearchProps('username', 'username'),
                    filteredValue: filteredInfo.username || null,
                },
            ]
            : []),
        {
            title: 'Serial',
            dataIndex: 'serial',
            key: 'serial',
            width: 120,
            align: 'center',
            ...getColumnSearchProps('serial', 'serial'),
            filteredValue: filteredInfo.serial || null,
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: 140,
            align: 'center'
        },
        {
            title: 'Expected Substances',
            dataIndex: 'expected_substances',
            key: 'expected_substances',
            width: 150,
            align: 'center',
            filters: [...expectedSubstances.sort().map(e => ({ text: e, value: e })), { text: 'Other', value: 'Other' }],
            onFilter: (value, record) => record.expected_substances?.includes(value),
            filteredValue: filteredInfo.expected_substances || null,
            render: substances => (
                substances?.map((e, i) => <Tag key={i} color="blue">{e}</Tag>)
            )
        },
        {
            title: 'Description',
            dataIndex: 'substance_description',
            key: 'substance_description',
            width: 250,
            align: 'center',
            ...getColumnSearchProps('substance_description', 'descriptions'),
            filteredValue: filteredInfo.substance_description || null,
            render: text => (
                text ? (
                    <Tooltip title={text}>
                        <span style={{ cursor: 'default' }}>{text.length > 30 ? `${text.slice(0, 30)}...` : text}</span>
                    </Tooltip>
                ) : null
            ),
        },
        {
            title: 'Strength',
            dataIndex: 'substance_strength',
            key: 'substance_strength',
            width: 130,
            align: 'center',
            filters: [
                {
                    text: 'Weak',
                    value: 'Weak',
                },
                {
                    text: 'Normal',
                    value: 'Normal',
                },
                {
                    text: 'Strong',
                    value: 'Strong',
                },
                {
                    text: 'Very Strong',
                    value: 'Very Strong',
                },
                {
                    text: 'Overdose',
                    value: 'Overdose',
                },
            ],
            onFilter: (value, record) => record.substance_strength === value,
            filteredValue: filteredInfo.substance_strength || null,
            render: text => {
                if (text) {
                    if (text.includes('Very Strong')) {
                        return <><WarningTwoTone twoToneColor="#faad14" /> {'Very Strong'}</>
                    } else if (text.includes('Overdose')) {
                        return <><WarningTwoTone twoToneColor="#f5222d" /> {'Overdose'}</>
                    } else {
                        return text
                    }
                }
            }
        },
        {
            title: 'Notes',
            dataIndex: 'notes',
            key: 'notes',
            width: 250,
            align: 'center',
            ...getColumnSearchProps('notes', 'notes'),
            filteredValue: filteredInfo.notes || null,
            render: text => (text ? `${text.length > 30 ? `${text.slice(0, 30)}...` : text}` : null)
        },
        {
            title: `Client Code`,
            dataIndex: 'client_code',
            key: 'client_code',
            width: 180,
            align: 'center',
            ...getColumnSearchProps('client_code', 'client code'),
            filteredValue: filteredInfo.client_code || null,
            render: c => (!isUUID(c) ? c : null)
        },
        {
            title: 'Operator',
            dataIndex: 'operator_id',
            key: 'operator_id',
            width: 100,
            align: 'center',
            filteredValue: filteredInfo.operator || null,
            render: operator_id => {
                if (isUUID(operator_id) && operatorsMap?.[operator_id]) {
                    const { first_name, last_name, color } = operatorsMap[operator_id];
                    return (
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', cursor: 'default' }}>
                            <Tooltip title={`${first_name} ${last_name}`}>
                                <Avatar size="small" shape="square" style={{ backgroundColor: color, color: '#fff', display: 'flex', alignItems: 'center', fontSize: '10px' }}>
                                    {first_name[0]}{last_name[0]}
                                </Avatar>
                            </Tooltip>
                        </div>
                    )
                } else { return `${((e = (operator_id || '')) => e.length > 10 ? `${e.slice(0, 10)}...` : e)()}` }
            }
        },
        {
            title: 'Action',
            key: 'map_action',
            width: 80,
            align: 'center',
            fixed: 'right',
            render: (record) => (
                <Space size="middle">
                    <Button
                        disabled={scanning || !record.device_id || !record.results || Object.keys(record.results).length === 0}
                        type="link"
                        size="small"
                        className={classes.actionButton}
                        onClick={e => {
                            e.stopPropagation();
                            message.loading({ content: 'Loading...', key, duration: 0 });
                            mapViewButtonHandler({ map_id: record.map_id, serial: record.serial, device_id: record.device_id }, showArchivedScans);
                            setImageInfoSerial(record.serial);
                        }}
                    >
                        View
                    </Button>
                </Space>
            ),
        },
    ];

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };

    const handleReset = clearFilters => {
        clearFilters();
    };

    const expandIcon = () => {
        return null;
    };

    const rangePickerChangeHandler = (dayjsDate, range) => {
        setDateRange({ from: dayjsDate[0].startOf('day'), to: dayjsDate[1].endOf('day') });
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
    };

    const saveScanMetadata = async (record) => {
        return new Promise(async (resolve, reject) => {
            try {
                message.loading({ content: 'Saving...', key, duration: 0 });
                if (!isUUID(record.operator_id)) { record.operator_id = null; }
                await updateRecord(record);
                message.success({ content: 'Scan info saved.', key, duration: 3 });
                resolve();
            } catch (error) {
                message.error({ content: 'Failed to save scan info.', key, duration: 6 });
                reject(error);
            }
        });
    }

    const updateRecordHandler = async (record) => {
        try {
            await saveScanMetadata(record);
            const relatedLabSample = massSpectrometryTableData.find(s => s.id === record.lab_sample_id);
            if (relatedLabSample && relatedLabSample.status === 'Pending Transit') {
                try {
                    await saveMassSpecRow({ ...relatedLabSample, status: 'In Progress' });
                    message.success(`Lab sample ${relatedLabSample.id.slice(0, 8).toUpperCase()} changed to "In Progress".`);
                    const index = massSpectrometryTableData.findIndex(test => test.id === relatedLabSample.id);
                    if (index !== -1) {
                        const updatedLabTestData = massSpectrometryTableData.map((item, idx) =>
                            idx === index ? { ...item, status: 'In Progress' } : item
                        );
                        setMassSpectrometryTableData(updatedLabTestData);
                    }
                } catch {
                    message.error(`Failed to update ${record.id.slice(0, 8).toUpperCase()}.`);
                }
            }
            record.newClientCode = false;
            record.newExpectedSubstances = false;
            record.newSubstanceStrength = false;
            record.newDescription = false;
            record.newImpact = false;
            record.newNotes = false;
            record.newOperator = false;
            record.newlabSampleID = false;
            let newAllScanData = cloneDeep(allScanData);
            let scanIndex = newAllScanData.findIndex(e => e.map_id == record.map_id);

            Object.keys(newAllScanData[scanIndex]).forEach((key) => {
                if (record.hasOwnProperty(key)) {
                    newAllScanData[scanIndex][key] = record[key];
                }
            });
            if (!isUUID(record.client_code) && !clientCodes.some(client => client.client_code === record.client_code)) {
                await fetchClientCodes(username).then((clientCodes) => {
                    setClientCodes(clientCodes);
                });
            }
            setAllScanData(newAllScanData);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (!showAdditionalFilters) {
            setSelectedSubstancesForResultFilter([]);
            setResultsFilterIsIncusive(true);
            setScanDetectedFilterMethod('AND');
        }
    }, [showAdditionalFilters]);

    useEffect(() => {
        resultsFilterIsIncusive === true ? setScanDetectedFilterMethod('AND') : setScanDetectedFilterMethod('OR');
    }, [resultsFilterIsIncusive]);

    const passesAdditionalFilters = record => {
        let pass = true;
        // let numSubstancesInResults = Object.keys(record?.results || {}).length;
        const numSubstancesInResults = record?.results?.length;
        if (selectedSubstancesForResultFilter.length && numSubstancesInResults > 0) {
            const substancesInResults = (record?.results || []).map(result => result.substance);
            if (scanDetectedFilterMethod === 'AND') {
                // pass = selectedSubstancesForResultFilter.every(substance => record.results.hasOwnProperty(substance)) ? true : false;
                pass = selectedSubstancesForResultFilter.every(substance => substancesInResults.includes(substance)) ? true : false;
            } else {
                // pass = selectedSubstancesForResultFilter.some(substance => record.results.hasOwnProperty(substance)) ? true : false;
                pass = selectedSubstancesForResultFilter.some(substance => substancesInResults.includes(substance)) ? true : false;
            }
            pass = resultsFilterIsIncusive ? pass : !pass;
        } else if (selectedSubstancesForResultFilter.length && numSubstancesInResults === 0) {
            pass = false;
        }
        return pass;
    }

    const searchDatabaseForLabSampleID = async searchString => {
        let searchResults = (await searchMassSpecData(searchString)).map(e => ({ key: e.id, ...e }));
        setMassSpectrometryTableData(prev => [...prev, ...searchResults]);
        return searchResults;
    }

    const handleLabSampleIDSearch = async input => {
        const searchString = (input?.target?.value ?? input ?? '').toLowerCase();
        let matchingLabSampleIDs = massSpectrometryTableData.filter(s => s.id.substring(0, 8).includes(searchString)).map(s => ({
            value: s.id,
            label: `${s.id.substring(0, 8).toUpperCase()} (${s.status})`
        }));
        if (searchString.length && !matchingLabSampleIDs.length) {
            const searchResults = await searchDatabaseForLabSampleID(searchString);
            matchingLabSampleIDs = searchResults.map(s => ({
                value: s.id,
                label: `${s.id.substring(0, 8).toUpperCase()} (${s.status})`
            }));
        }
        setLabSampleIDMatches(matchingLabSampleIDs);
    }

    const fetchRecordLabSampleData = async id => {
        if (!id) {
            setRecordLabSampleData(null);
            return;
        }
        let sampleData = massSpectrometryTableData.find(s => s.id === id);
        if (!sampleData) {
            const searchResults = await searchDatabaseForLabSampleID(id.substring(0, 8));
            sampleData = searchResults.find(s => s.id === id);
        }
        setRecordLabSampleData(sampleData);
        return;
    }

    const autoSaveRecord = async (serial) => {
        const record = allScanData.find(e => e.serial === serial);
        if (showArchivedScans || username === 'admin' || !record) { return; }
        if (!record.client_code) {
            record.client_code = crypto.randomUUID();
        }
        try {
            await saveScanMetadata(record);
            await saveClientData(record);
        } catch (error) {
            console.error(error);
        }
    }

    const loadResults = async (record) => {
        if (!record?.map_id) return;
        setLoadingResults(true);
        if (showArchivedScans) {
            const results = await fetchArchivedResults(record.map_id);
            if (Object.keys(results).length) {
                const scanIndex = archivedScans.findIndex(e => e.key == record.serial);
                if (scanIndex !== -1) {
                    setArchivedScans(prevArchivedScans => {
                        let newArchivedScans = [...prevArchivedScans];
                        newArchivedScans[scanIndex] = { ...newArchivedScans[scanIndex], results };
                        return newArchivedScans;
                    });
                }
            }
            setLoadingResults(false);
        } else {
            const results = await fetchResults(record.map_id);
            if (Object.keys(results).length) {
                let scanIndex = allScanData.findIndex(e => e.key == record.serial);
                if (scanIndex !== -1) {
                    setAllScanData(prevAllScanData => {
                        let newAllScanData = [...prevAllScanData];
                        newAllScanData[scanIndex] = { ...newAllScanData[scanIndex], results };
                        return newAllScanData;
                    });
                }
                setLoadingResults(false);
            } else {
                let mapFromDB = await fetchSpectrum(record.map_id);
                if (mapFromDB.length > 0) {
                    clientUploadsWorker.postMessage({ type: 'PREDICT', serial: record.serial, mapID: record.map_id, data: mapFromDB });
                } else {
                    let scanIndex = allScanData.findIndex(e => e.key == record.serial);
                    if (scanIndex !== -1) {
                        setAllScanData(prevAllScanData => {
                            let newAllScanData = [...prevAllScanData];
                            newAllScanData[scanIndex].results = {};
                            return newAllScanData;
                        });
                    }
                    setLoadingResults(false);
                }
            }
        }
    }

    const handleIncludeTraceSwitch = async (checked) => {
        if (!showArchivedScans) {
            const recordIndex = allScanData.findIndex(e => e.key === expandedRows[0]);
            if (recordIndex === -1) return;
            setAllScanData(prevAllScanData => {
                let newAllScanData = [...prevAllScanData];
                newAllScanData[recordIndex] = { ...newAllScanData[recordIndex], showTrace: checked };
                return newAllScanData;
            });
        } else if (showArchivedScans) {
            const recordIndex = archivedScans.findIndex(e => e.key === expandedRows[0]);
            if (recordIndex === -1) return;
            setArchivedScans(prevArchivedScans => {
                let newArchivedScans = [...prevArchivedScans];
                newArchivedScans[recordIndex] = { ...newArchivedScans[recordIndex], showTrace: checked };
                return newArchivedScans;
            });
        }
    }

    return (
        <>
            <ImageModal />
            <Row justify="center" align="middle">
                <Col span={21} style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                    <Title level={3}>{showArchivedScans ? 'Archived Scans' : 'Scans'}</Title>
                </Col>
            </Row >
            <Row justify="center" align="middle">
                <Col span={21} style={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip title={`${showAdditionalFilters ? 'Hide' : 'Show'} additional filters`} placement="topLeft">
                        <Button
                            type="default"
                            icon={<FilterOutlined />}
                            className={classes.topButtons}
                            onClick={() => {
                                setShowAdditionalFilters(!showAdditionalFilters)
                            }}
                            disabled={scanning}
                        />
                    </Tooltip>
                    <RangePicker
                        style={{ flex: '1', margin: '0 3px' }}
                        className={classes.rangePicker}
                        onChange={rangePickerChangeHandler}
                        value={[dayjs(dateRange.from), dayjs(dateRange.to)]}
                        presets={[
                            { label: 'This Week', value: [dayjs().startOf('week'), dayjs().endOf('week')] },
                            { label: 'Last Week', value: [dayjs().subtract(1, 'weeks').startOf('week'), dayjs().subtract(1, 'weeks').endOf('week')] },
                            { label: 'This Month', value: [dayjs().startOf('month'), dayjs().endOf('month')] },
                            { label: 'Last 4 Weeks', value: [dayjs().subtract(3, 'weeks').startOf('week'), dayjs().endOf('week')] },
                            { label: 'Last Month', value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')] },
                            { label: 'This Year', value: [dayjs().startOf('year'), dayjs().endOf('year')] }
                        ]}
                        allowClear={false}
                        disabled={scanning}
                        format={(value) => dayjs(value).format('MMM D, YYYY')}
                    />
                    <Tooltip title="Clear filters" placement="topRight">
                        <Button
                            type="default"
                            icon={<UndoOutlined />}
                            className={classes.topButtons}
                            onClick={() => {
                                setFilteredInfo({});
                                setSelectedSubstancesForResultFilter([]);
                                setResultsFilterIsIncusive(true);
                                setScanDetectedFilterMethod('AND');
                            }}
                            disabled={scanning}
                        />
                    </Tooltip>
                </Col>
            </Row>
            {
                showAdditionalFilters ?
                    <Row justify="center" align="middle" style={{ margin: '6px 0' }}>
                        <Col span={21} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <Space.Compact block style={{ flex: 1, margin: '0 3px 0 0' }}>
                                <Button
                                    style={{
                                        cursor: 'default',
                                        pointerEvents: 'none',
                                    }}
                                >
                                    Scan
                                </Button>
                                <Select
                                    style={{ flex: 'none', width: 'auto', textAlign: 'center' }}
                                    suffixIcon={null}
                                    popupMatchSelectWidth={false}
                                    value={resultsFilterIsIncusive}
                                    onChange={(e) => setResultsFilterIsIncusive(e)}
                                    options={[
                                        {
                                            label: 'detected',
                                            value: true,
                                        },
                                        {
                                            label: 'did not detect',
                                            value: false,
                                        },
                                    ]}
                                />
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{ flex: '1' }}
                                    suffixIcon={null}
                                    placeholder="Add substances"
                                    value={selectedSubstancesForResultFilter}
                                    onChange={(e) => setSelectedSubstancesForResultFilter(e)}
                                    options={detectedSubstances.map(s => ({ label: s[0], value: s[0] }))}
                                />
                            </Space.Compact>
                            {
                                selectedSubstancesForResultFilter.length >= 2 ?
                                    <Radio.Group
                                        style={{ flexShrink: 0, margin: '0 3px' }}
                                        options={[
                                            { label: 'AND', value: 'AND' },
                                            { label: 'OR', value: 'OR' },
                                        ]}
                                        onChange={(e) => { setScanDetectedFilterMethod(e.target.value); }}
                                        value={scanDetectedFilterMethod}
                                        optionType="button" /> : null
                            }
                            <Tooltip title={`${showArchivedScans ? 'Hide' : 'Show'} archived scans`} placement="topLeft">
                                <Button
                                    type="default"
                                    icon={<InboxOutlined />}
                                    className={classes.topButtons}
                                    onClick={() => setShowArchivedScans(!showArchivedScans)}
                                    disabled={scanning}
                                />
                            </Tooltip>
                        </Col>
                    </Row> : null
            }
            <Row justify="center" align="middle" style={{ marginTop: "6px" }}>
                <Col span={21} style={{ textAlign: "center" }} >
                    <Table
                        dataSource={showArchivedScans ? archivedScans.filter(passesAdditionalFilters) : allScanData.filter(passesAdditionalFilters)}
                        onChange={handleTableChange}
                        columns={clientUploadColumns}
                        className={classes.mapsTable}
                        expandable={{
                            columnWidth: 1,
                            expandRowByClick: true,
                            expandIconColumnIndex: -1,
                            expandIcon,
                            expandedRowKeys: expandedRows,
                            onExpand: (expanded, record) => {
                                if (scanning) return;
                                setIsTestInfoModalOpen(false);
                                if (expandedRows[0]) { autoSaveRecord(expandedRows[0]) };
                                setExpandedRows(expanded ? [record.key] : []);
                                fetchRecordLabSampleData(record?.lab_sample_id);
                                if (expanded) loadResults(record);
                            },
                            expandedRowRender: record => {
                                if (record.serial !== expandedRows[0]) {
                                    return null;
                                }
                                return (
                                    <>
                                        {(record.serial === currentSerialNumber.current && scanning) ? <Badge status="processing" text="In Progress" style={{ position: 'absolute', right: '30px' }} /> : null}
                                        <Descriptions title="Scan Info" bordered={true} column={1} size="small" className={classes.expandedTable}>
                                            <Descriptions.Item label="Serial No." labelStyle={{ width: '30px' }}><div>{record.serial}</div></Descriptions.Item>
                                            <Descriptions.Item label="Date">{dayjs(record.created_at).format('MMM D, YYYY')}</Descriptions.Item>
                                            <Descriptions.Item label="Operator">
                                                <Select
                                                    showSearch
                                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                                    suffixIcon={null}
                                                    style={{ width: '100%' }}
                                                    className={(record.operator_id || record.operator) && !record.newOperator ? classes.cellHasData : null}
                                                    value={record.operator_id || record.operator}
                                                    // Uncomment when antd version >= 5.15.0
                                                    // labelRender={(label) => {
                                                    //     if (record.operator_id && operatorsMap?.[label.value]) {
                                                    //         const { first_name, last_name, color } = operatorsMap[label.value];
                                                    //         return (
                                                    //             <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }} >
                                                    //                 <p style={{ margin: '0px' }}>{first_name} {last_name}</p>
                                                    //                 <Avatar size="small" shape="square" style={{ backgroundColor: color, color: '#fff', display: 'flex', alignItems: 'center', fontSize: '10px' }}>
                                                    //                     {first_name[0]}{last_name[0]}
                                                    //                 </Avatar>
                                                    //             </div>
                                                    //         );
                                                    //     } else { return record.operator_id || record.operator; }
                                                    // }}
                                                    optionRender={(option) => (
                                                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }} >
                                                            <p style={{ margin: '0px' }}>{option.data.first_name} {option.data.last_name}</p>
                                                            <Avatar size="small" shape="square" style={{ backgroundColor: option.data.color, color: '#fff', display: 'flex', alignItems: 'center', fontSize: '10px' }}>
                                                                {option.data.first_name[0]}{option.data.last_name[0]}
                                                            </Avatar>
                                                        </div>
                                                    )}
                                                    onChange={e => { record.operator_id = e; record.newOperator = true; handleRecordChange(record); }}
                                                    options={operators.map(operator => ({ ...operator, value: operator.operator_id, label: `${operator.first_name} ${operator.last_name}` }))}
                                                />
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Client Code">
                                                <AutoComplete
                                                    style={{ width: '100%' }}
                                                    className={record.client_code && !record.newClientCode ? classes.cellHasData : null}
                                                    options={clientCodes.map(e => ({ value: e.client_code })).filter(e => !isUUID(e.value))}
                                                    placeholder="Add client code"
                                                    onChange={(e) => {
                                                        if (record.client_code && !record.newClientCode) { participantProfileFormRef.current.storedClientCode = record.client_code; }
                                                        record.client_code = e;
                                                        record.newClientCode = true;
                                                        (throttle(_ => {
                                                            const matchingClient = clientCodes.find(client => client.client_code === record.client_code);
                                                            if (matchingClient) {
                                                                record.gender = matchingClient.gender;
                                                                record.age = matchingClient.age;
                                                                record.chronic_pain = matchingClient.chronic_pain;
                                                                record.indigenous = matchingClient.indigenous;
                                                                record.brpoc = matchingClient.brpoc;
                                                                record.lgbtq = matchingClient.lgbtq;
                                                                record.preferred_language = matchingClient.preferred_language;
                                                                participantProfileFormRef.current.setFieldsValue({
                                                                    gender: matchingClient.gender,
                                                                    age: matchingClient.age,
                                                                    chronic_pain: matchingClient.chronic_pain,
                                                                    indigenous: matchingClient.indigenous,
                                                                    brpoc: matchingClient.brpoc,
                                                                    lgbtq: matchingClient.lgbtq,
                                                                    preferred_language: matchingClient.preferred_language
                                                                });
                                                            } else {
                                                                record.gender = participantProfileFormRef.current.gender;
                                                                record.age = participantProfileFormRef.current.age;
                                                                record.chronic_pain = participantProfileFormRef.current.chronic_pain;
                                                                record.indigenous = participantProfileFormRef.current.indigenous;
                                                                record.brpoc = participantProfileFormRef.current.brpoc;
                                                                record.lgbtq = participantProfileFormRef.current.lgbtq;
                                                                record.preferred_language = participantProfileFormRef.current.preferred_language;
                                                                participantProfileFormRef.current.setFieldsValue({
                                                                    gender: participantProfileFormRef.current.gender,
                                                                    age: participantProfileFormRef.current.age,
                                                                    chronic_pain: participantProfileFormRef.current.chronic_pain,
                                                                    indigenous: participantProfileFormRef.current.indigenous,
                                                                    brpoc: participantProfileFormRef.current.brpoc,
                                                                    lgbtq: participantProfileFormRef.current.lgbtq,
                                                                    preferred_language: participantProfileFormRef.current.preferred_language
                                                                });
                                                            }
                                                            handleRecordChange(record);
                                                        }, 300))()
                                                    }}
                                                    allowClear={true}
                                                    onClear={(e) => {
                                                        handleRecordChange(record);
                                                        participantProfileFormRef.current.setFieldsValue({
                                                            gender: participantProfileFormRef.current.gender,
                                                            age: participantProfileFormRef.current.age,
                                                            chronic_pain: participantProfileFormRef.current.chronic_pain,
                                                            indigenous: participantProfileFormRef.current.indigenous,
                                                            brpoc: participantProfileFormRef.current.brpoc,
                                                            lgbtq: participantProfileFormRef.current.lgbtq,
                                                            preferred_language: participantProfileFormRef.current.preferred_language
                                                        });
                                                    }}
                                                    value={record.client_code ? record.client_code : ``}
                                                    filterOption={(inputValue, option) =>
                                                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                    }
                                                />
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Client Profile">
                                                <Form
                                                    ref={participantProfileFormRef}
                                                    initialValues={{
                                                        gender: record.gender ? record.gender : undefined,
                                                        age: record.age ? record.age : undefined,
                                                        chronic_pain: record.chronic_pain !== null ? record.chronic_pain : undefined,
                                                        indigenous: record.indigenous !== null ? record.indigenous : undefined,
                                                        brpoc: record.brpoc !== null ? record.brpoc : undefined,
                                                        lgbtq: record.lgbtq !== null ? record.lgbtq : undefined,
                                                        preferred_language: record.preferred_language ? record.preferred_language : 'en',
                                                    }}
                                                    labelCol={{
                                                        xs: { span: 24 },
                                                        sm: { span: 14 },
                                                        md: { span: 9 },
                                                        lg: { span: 8 },
                                                    }}
                                                    wrapperCol={{
                                                        xs: { span: 24 },
                                                        sm: { span: 10 },
                                                        md: { span: 15 },
                                                        lg: { span: 16 },
                                                    }}
                                                    onFieldsChange={() => {
                                                        if (!record.client_code) {
                                                            record.client_code = crypto.randomUUID(); record.newClientCode = true; handleRecordChange(record);
                                                        }
                                                    }}
                                                    onFinish={async values => {
                                                        if (!record.client_code) {
                                                            record.client_code = crypto.randomUUID(); record.newClientCode = true; handleRecordChange(record);
                                                        }
                                                        for (let key in values) {
                                                            if (values.hasOwnProperty(key) && values[key] === undefined && record.hasOwnProperty(key)) {
                                                                values[key] = record[key];
                                                            }
                                                        }
                                                        saveClientData({ client_code: record.client_code, username, ...values });
                                                        if (participantProfileFormRef?.current?.storedClientCode) {
                                                            let response = await deleteClient(username, participantProfileFormRef.current.storedClientCode);
                                                            if (response.clientCode) {
                                                                message.info(`Client deleted: ${response.clientCode}`, 6);
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <Form.Item label="Gender" name="gender" style={{ marginBottom: '8px' }}>
                                                        <Select
                                                            allowClear
                                                            suffixIcon={null}
                                                            style={{ width: '100%' }}
                                                            placeholder="Select gender"
                                                            onChange={e => { record.gender = e; handleRecordChange(record); participantProfileFormRef.current.gender = e; }}
                                                        >
                                                            <Option key={'Man'}>{'Man'}</Option>
                                                            <Option key={'Woman'}>{'Woman'}</Option>
                                                            <Option key={'Non-binary'}>{'Non-binary'}</Option>
                                                            <Option key={'Prefer not to say'}>{'Prefer not to say'}</Option>
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item label="Age" name="age" style={{ marginBottom: '8px' }} rules={[{ pattern: /^\d{2}$/, message: '' }]}>
                                                        <Input
                                                            style={{ width: '100%' }}
                                                            onChange={(e) => {
                                                                const ageValue = parseInt(e.target.value, 10);
                                                                if (!isNaN(ageValue) && ageValue >= 10 && ageValue <= 99) {
                                                                    record.age = ageValue;
                                                                    handleRecordChange(record);
                                                                    participantProfileFormRef.current.age = ageValue;
                                                                }
                                                            }}
                                                            value={record.age}
                                                            placeholder="Enter a number"
                                                            onKeyDown={(e) => {
                                                                if (!/\d/.test(e.key) && !['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'].includes(e.key)) {
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item label="Chronic Pain" name="chronic_pain" style={{ marginBottom: '8px' }}>
                                                        <Radio.Group options={[
                                                            { label: 'Yes', value: true },
                                                            { label: 'No', value: false },
                                                        ]} optionType="button"
                                                            onChange={(e) => { record.chronic_pain = e.target.value; handleRecordChange(record); participantProfileFormRef.current.chronic_pain = e.target.value; }} />
                                                    </Form.Item>
                                                    <Form.Item label="Indigenous" name="indigenous" style={{ marginBottom: '8px' }}>
                                                        <Radio.Group options={[
                                                            { label: 'Yes', value: true },
                                                            { label: 'No', value: false },
                                                        ]} optionType="button"
                                                            onChange={(e) => { record.indigenous = e.target.value; handleRecordChange(record); participantProfileFormRef.current.indigenous = e.target.value; }} />
                                                    </Form.Item>
                                                    <Form.Item label="Black, racialized or a POC" name="brpoc" style={{ marginBottom: '8px' }}>
                                                        <Radio.Group options={[
                                                            { label: 'Yes', value: true },
                                                            { label: 'No', value: false },
                                                        ]} optionType="button"
                                                            onChange={(e) => { record.brpoc = e.target.value; handleRecordChange(record); participantProfileFormRef.current.brpoc = e.target.value; }} />
                                                    </Form.Item>
                                                    <Form.Item label="2SLGBTQIA+" name="lgbtq" style={{ marginBottom: '8px' }}>
                                                        <Radio.Group options={[
                                                            { label: 'Yes', value: true },
                                                            { label: 'No', value: false },
                                                        ]} optionType="button"
                                                            onChange={(e) => { record.lgbtq = e.target.value; handleRecordChange(record); participantProfileFormRef.current.lgbtq = e.target.value; }} />
                                                    </Form.Item>
                                                    <Form.Item label="Preferred Language" name="preferred_language" style={{ marginBottom: '0px' }}>
                                                        <Select
                                                            showSearch
                                                            placeholder="Select language"
                                                            filterOption={(input, option) =>
                                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                            }
                                                            options={languages}
                                                            onChange={e => { record.preferred_language = e; handleRecordChange(record); participantProfileFormRef.current.preferred_language = e; }}
                                                        />
                                                    </Form.Item>
                                                </Form>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Testing for">
                                                <Radio.Group
                                                    options={[
                                                        { label: 'Self', value: 'self' },
                                                        { label: 'Third-Party', value: 'third-party' },
                                                        { label: 'Both', value: 'both' },
                                                    ]}
                                                    optionType="button"
                                                    value={record.testing_for}
                                                    onChange={(e) => { record.testing_for = e.target.value; handleRecordChange(record); }}
                                                />
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Expected Substances">
                                                <Select
                                                    mode="multiple"
                                                    allowClear
                                                    suffixIcon={null}
                                                    style={{ width: '100%' }}
                                                    className={(record.expected_substances || []).length > 0 && !record.newExpectedSubstances ? classes.cellHasData : null}
                                                    placeholder="Add expected substances"
                                                    value={record.expected_substances ? record.expected_substances : undefined}
                                                    onChange={e => { record.expected_substances = e; record.newExpectedSubstances = true; handleRecordChange(record); }}
                                                >
                                                    {expectedSubstances.map(e => <Option key={e}>{e}</Option>)}
                                                    <Option key={'Other'}>{'Other (Please specify in notes)'}</Option>
                                                </Select>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Description">
                                                <TextArea
                                                    className={record.substance_description && !record.newDescription ? classes.cellHasData : null}
                                                    placeholder="Colour, consistency, special attributes..."
                                                    autoSize
                                                    value={record.substance_description}
                                                    onChange={e => { record.substance_description = e.target.value; record.newDescription = true; handleRecordChange(record); }}
                                                />
                                            </Descriptions.Item>
                                            <Descriptions.Item label={<span>Results<br /><Tooltip placement="right" title="View in window"><ExportOutlined onClick={() => { setImageInfoSerial(record.serial); setIsTestInfoModalOpen(true); }} /></Tooltip></span>}>
                                                {loadingResults ?
                                                    <Skeleton.Button active={true} block={true} style={{ width: '100%', height: '145px' }} />
                                                    : (record?.results?.length > 0 && !(record?.results?.length === 1 && record?.results[0].substance === 'Unknown')) ?
                                                        <div style={{ position: 'relative' }}>
                                                            <Switch type="primary" size="small" ghost checkedChildren="Trace" unCheckedChildren="Trace" style={{ position: 'absolute', top: '0px', right: '21px', zIndex: 2 }}
                                                                value={record.showTrace} onClick={handleIncludeTraceSwitch} />
                                                            <VisualResultsTable record={record} />
                                                        </div>
                                                        : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"No Results"}
                                                            style={{ height: '145px', margin: '0', display: 'flex', flexDirection: 'column', justifyContent: 'center' }} />
                                                }
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Scan Impact">
                                                <Select
                                                    allowClear
                                                    suffixIcon={null}
                                                    style={{ width: '100%' }}
                                                    className={record.impact && !record.newImpact ? classes.cellHasData : null}
                                                    disabled={!record.results}
                                                    placeholder="Select impact"
                                                    value={record.impact}
                                                    onChange={e => { record.impact = e; record.newImpact = true; handleRecordChange(record); }}
                                                >
                                                    <Option key={'Unknown'}>Unknown</Option>
                                                    <Option key={'No Change'}>No Change</Option>
                                                    <Option key={'Lowered Dose'}>Lowered Dose</Option>
                                                    <Option key={'Increased Dose'}>Increased Dose</Option>
                                                    <Option key={'Did Not Consume'}>Did Not Consume</Option>
                                                </Select>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Strength">
                                                <Select
                                                    allowClear
                                                    suffixIcon={null}
                                                    style={{ width: '100%' }}
                                                    className={record.substance_strength && !record.newSubstanceStrength ? classes.cellHasData : null}
                                                    placeholder="Select strength"
                                                    value={record.substance_strength}
                                                    disabled={(record.impact === 'Did Not Consume' ? true : false)}
                                                    onChange={e => { record.substance_strength = e; record.newSubstanceStrength = true; handleRecordChange(record); setRiskAwarenessShouldUpdate(true); }}
                                                >
                                                    <Option key={'Weak'}>{'Weak'}</Option>
                                                    <Option key={'Normal'}>{'Normal'}</Option>
                                                    <Option key={'Strong'}>{'Strong'}</Option>
                                                    <Option key={'Very Strong'}><span><WarningTwoTone twoToneColor="#faad14" /> {'Very Strong'}</span></Option>
                                                    <Option key={'Overdose'}><span><WarningTwoTone twoToneColor="#f5222d" /> {'Overdose'}</span></Option>
                                                </Select>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Consumed">
                                                <Radio.Group
                                                    options={[
                                                        { label: 'Onsite', value: 'onsite' },
                                                        { label: 'Offsite', value: 'offsite' },
                                                        { label: 'N/A', value: 'na' },
                                                    ]}
                                                    optionType="button"
                                                    value={record.consumed}
                                                    onChange={(e) => { record.consumed = e.target.value; handleRecordChange(record); }}
                                                />
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Notes">
                                                <TextArea
                                                    className={record.notes && !record.newNotes ? classes.cellHasData : null}
                                                    placeholder="Side effects, amount consumed, onset, administered first aid..."
                                                    autoSize
                                                    value={record.notes}
                                                    onChange={e => { record.notes = e.target.value; record.newNotes = true; handleRecordChange(record); }}
                                                />
                                            </Descriptions.Item>
                                            {isAdmin &&
                                                <Descriptions.Item label="Laboratory Sample ID">
                                                    <Select
                                                        allowClear
                                                        showSearch
                                                        suffixIcon={null}
                                                        style={{ width: '100%' }}
                                                        className={record.lab_sample_id && !record.newlabSampleID ? classes.cellHasData : null}
                                                        placeholder="Search for sample"
                                                        value={recordLabSampleData ? `${recordLabSampleData.id.substring(0, 8).toUpperCase()} (${recordLabSampleData.status})` : null}
                                                        onChange={e => { record.lab_sample_id = e; record.newlabSampleID = true; handleRecordChange(record); }}
                                                        onFocus={handleLabSampleIDSearch}
                                                        onSearch={handleLabSampleIDSearch}
                                                        options={labSampleIDMatches || []}
                                                    />
                                                    {recordLabSampleData ?
                                                        <Descriptions style={{ marginTop: '8px' }} bordered={true} column={1} size="small" labelStyle={{ width: '120px' }}>
                                                            <Descriptions.Item label="Request Date">{dayjs(recordLabSampleData.created_at).format('MMM D, YYYY')}</Descriptions.Item>
                                                            <Descriptions.Item label="Expected">{recordLabSampleData.expected_substances?.map(substance => (
                                                                <Tag key={substance}>{substance}</Tag>
                                                            ))}</Descriptions.Item>
                                                            <Descriptions.Item label="Description">{recordLabSampleData.description}</Descriptions.Item>
                                                            <Descriptions.Item label="Client Code">{recordLabSampleData.client_code}</Descriptions.Item>
                                                            <Descriptions.Item label="Lab Notes">{recordLabSampleData.laboratory_comments}</Descriptions.Item>
                                                            <Descriptions.Item label="Username">{recordLabSampleData.username}</Descriptions.Item>
                                                        </Descriptions> : null}
                                                </Descriptions.Item>
                                            }
                                            <Descriptions.Item label="Action">
                                                <Button
                                                    type="primary" ghost
                                                    disabled={scanning || !record.device_id}
                                                    onClick={() => {
                                                        mapViewButtonHandler({ map_id: record.map_id, serial: record.serial, device_id: record.device_id }, showArchivedScans);
                                                        message.loading({ content: 'Loading...', key, duration: 0 });
                                                        setImageInfoSerial(record.serial);
                                                    }}
                                                >
                                                    View
                                                </Button>
                                                {showArchivedScans || username === 'admin' ? null :
                                                    <Button
                                                        type="primary" ghost
                                                        style={{ marginLeft: '6px' }}
                                                        onClick={() => { updateRecordHandler(record); participantProfileFormRef.current.submit(); }}
                                                    >
                                                        Save Info
                                                    </Button>
                                                }
                                                {record.image_filename && currentUser?.attributes['custom:Role'] === '1' ?
                                                    <Button
                                                        type="primary" ghost
                                                        style={{ marginLeft: '6px' }}
                                                        onClick={() => {
                                                            setImageFilename(record.image_filename);
                                                            setIsImageModalOpen(true);
                                                        }}
                                                    >
                                                        Show Image
                                                    </Button> : null
                                                }
                                                <Button
                                                    type="primary" danger ghost
                                                    style={{ float: 'right' }}
                                                    disabled={scanning}
                                                    onClick={() => {
                                                        showArchivedScans ? confirmRestore(record) : confirmRecordArchive(record);
                                                    }}
                                                >{showArchivedScans ? 'Restore' : 'Archive'}</Button>
                                            </Descriptions.Item>
                                        </Descriptions>
                                        {isTestInfoModalOpen ? createPortal(<TestInfoModal record={record} handleIncludeTraceSwitch={handleIncludeTraceSwitch} />, document.body) : null}
                                    </>
                                );
                            },
                            rowExpandable: record => true,
                        }
                        }
                        pagination={{
                            position: ['bottomCenter'],
                            pageSize: 10,
                            showSizeChanger: false,
                            disabled: scanning,
                            current: currentPage,
                            onChange: (page) => setCurrentPage(page),
                        }}
                        scroll={{ x: '1235px' }}
                        sticky
                        loading={dataLoading}
                    />
                </Col>
            </Row>
        </>
    )
}

export default ClientUploads;