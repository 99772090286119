let minIntensity = 0;
let maxIntensity = 65535;

export const getMappingGridConfig = (center) => {
    return {
        tooltip: {
            formatter: function (params) {
                return `
                <table>
                <tr>
                <td>Index</td>
                <td>${params.value[4]}</td>
                </tr>
                <tr>
                <td>Label</td>
                <td>${params.seriesName}</td>
                </tr>
                <tr>
                <td style="padding-right:5px">Coordinate</td>
                <td>(${params.value[3]}, ${params.value[1]}, ${params.value[2]})</td>
                </tr>
                <tr>
                <td>Value</td>
                <td>${params.value[5]}</td>
                </tr>
                <tr>
                <td>RE</td>
                <td>${params.value[7]}</td>
                </tr>
                ${params.value[6] ? `
                </tr>
                <tr>
                <td>Control Neuron</td>
                <td>${params.value[8]}</td>
                </tr>
                <tr>
                <td>Output (Average)</td>
                </tr>
                <tr>
                <td></td>
                <td>${params.value[6][0][0]}</td>
                <td>(${params.value[6][0][1]})←</td>
                <td>[${params.value[6][0][2]},</td>
                <td>${params.value[6][0][3]}]</td>
                </tr>
                <tr>
                <td></td>
                <td>${params.value[6][1][0]}</td>
                <td>(${params.value[6][1][1]})←</td>
                <td>[${params.value[6][1][2]},</td>
                <td>${params.value[6][1][3]}]</td>
                </tr>
                <tr>
                <td></td>
                <td>${params.value[6][2][0]}</td>
                <td>(${params.value[6][2][1]})←</td>
                <td>[${params.value[6][2][2]},</td>
                <td>${params.value[6][2][3]}]</td>
                </tr>
                <tr>
                <td></td>
                <td>${params.value[6][3][0]}</td>
                <td>(${params.value[6][3][1]})←</td>
                <td>[${params.value[6][3][2]},</td>
                <td>${params.value[6][3][3]}]</td>
                </tr>
                <tr>
                <td></td>
                <td>${params.value[6][4][0]}</td>
                <td>(${params.value[6][4][1]})←</td>
                <td>[${params.value[6][4][2]},</td>
                <td>${params.value[6][4][3]}]</td>
                </tr>
                <tr>
                <td></td>
                <td>${params.value[6][5][0]}</td>
                <td>(${params.value[6][5][1]})←</td>
                <td>[${params.value[6][5][2]},</td>
                <td>${params.value[6][5][3]}]</td>
                </tr>
                ` : ''}
                </table>
                `;
            },
        },
        visualMap: [
            {
                show: false,
                type: 'continuous',
                min: minIntensity,
                max: maxIntensity,
                range: [minIntensity, maxIntensity],
                calculable: true,
                hoverLink: false,
                dimension: 5,
                inRange: {
                    symbolSize: [2, 15]
                },
                outRange: {
                    symbolSize: [2, 15]
                },
            },
        ],
        legend: {
            type: 'scroll',
            bottom: 1,
            data: [],
        },
        xAxis3D: {
            name: '',
            type: 'value',
            color: '#fff',
            min: center.x + 3,
            max: center.x - 3,
        },
        yAxis3D: {
            name: '',
            type: 'value',
            min: center.y - 3,
            max: center.y + 3,
        },
        zAxis3D: {
            name: '',
            type: 'value',
            min: center.z - 3,
            max: center.z + 3,
        },
        grid3D: {
            top: '-5%',
            axisLine: {
                lineStyle: { color: '#8c8c8c', opacity: 0 }
            },
            axisPointer: {
                show: false,
                lineStyle: { color: '#8c8c8c' }
            },
            viewControl: {
                distance: 210,
                // distance: 120,
            },
            axisLabel: {
                show: false,
            },
            axisTick: {
                show: false,
            },
            splitLine: {
                // show: false,
                lineStyle: { opacity: 0.3 }
            },
        },
        series: [
            {
                type: 'line3D',
                silent: true,
                animationDurationUpdate: 300,
                animationEasingUpdate: 'elasticOut',
                lineStyle: {
                    width: 3,
                    opacity: 1,
                    color: '#1890ff',
                },
                data: []
            },
        ]
    }
};