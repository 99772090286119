import React, { useMemo } from 'react';
import { useAtom } from 'jotai';
import { Row, Col, Typography, InputNumber } from 'antd';
import { ColumnHeightOutlined, ColumnWidthOutlined, MenuOutlined, DashOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import {
  scanningState,
  scanHeightState,
  scanWidthState,
  pointsPerSideState,
  numLevelsState,
  singlePointMultiplierState,
  numPointsState,
} from '../stateManagement/processState';
import { mapState } from '../stateManagement/commonState';
import { maxIntegrationTimeState } from '../stateManagement/controlButtonState';

const { Text } = Typography;

export const AdditionalSettings = () => {
  const [scanning] = useAtom(scanningState);
  const [scanHeight, setScanHeight] = useAtom(scanHeightState);
  const [scanWidth, setScanWidth] = useAtom(scanWidthState);
  const [pointsPerSide, setPointsPerSide] = useAtom(pointsPerSideState);
  const [numLevels, setNumLevels] = useAtom(numLevelsState);
  const [numPoints] = useAtom(numPointsState);
  const [singlePointMultiplier, setSinglePointMultiplier] = useAtom(singlePointMultiplierState);
  const [map] = useAtom(mapState);
  const [maxIntegrationTime] = useAtom(maxIntegrationTimeState);

  const pointsPerSideInputHandler = useMemo(() => debounce((e) => { void (e && setPointsPerSide(e)) }, 90), []);
  const levelsInputHandler = useMemo(() => debounce((e) => { setNumLevels(e) }, 90), []);
  const scanWidthInputHandler = useMemo(() => debounce((e) => {
    setScanWidth(e);
    if (e === 0) { setPointsPerSide(1) };
  }, 90), []);
  const scanHeightInputHandler = useMemo(() => debounce((e) => { setScanHeight(e) }, 90), []);
  const singlePointMultiplierInputHandler = useMemo(() => debounce((e) => setSinglePointMultiplier(e), 90), []);

  const timeEstimate = () => {
    let numSeconds = pointsPerSide === 1 && numLevels === 1 ? singlePointMultiplier * (maxIntegrationTime / 1000) : numPoints * (maxIntegrationTime / 1000);
    return (<Text>{'Estimated Time: '}
      {numSeconds >= 60 ? `${Math.floor(numSeconds / 60)} minute${Math.floor(numSeconds / 60) > 1 ? 's' : ''} and ` : ''}
      {numSeconds % 60} second{numSeconds % 60 === 1 ? '' : 's'}</Text>
    );
  }

  return (
    <>
      <Row justify="center" align="middle" gutter={[8, 8]}>
        <Col xs={{ span: 9 }} md={{ span: 3 }}>
          <InputNumber
            addonBefore={<DashOutlined />}
            value={pointsPerSide}
            min={1}
            max={100}
            onChange={pointsPerSideInputHandler}
            disabled={scanning || map.length}
          />
        </Col>
        <Col xs={{ span: 9 }} md={{ span: 3 }}>
          <InputNumber
            addonBefore={<MenuOutlined />}
            value={numLevels}
            min={1}
            max={100}
            onChange={levelsInputHandler}
            disabled={scanning || map.length}
          />
        </Col>
        <Col xs={{ span: 9 }} md={{ span: 3 }}>
          <InputNumber
            addonBefore={<ColumnWidthOutlined />}
            value={scanWidth}
            min={0}
            max={8}
            formatter={value => `${value} mm`}
            parser={value => value.replace(' mm', '')}
            onChange={scanWidthInputHandler}
            disabled={scanning || map.length}
          />
        </Col>
        <Col xs={{ span: 9 }} md={{ span: 3 }}>
          <InputNumber
            addonBefore={<ColumnHeightOutlined />}
            value={scanHeight}
            min={0}
            max={20}
            formatter={value => `${value} mm`}
            parser={value => value.replace(' mm', '')}
            onChange={scanHeightInputHandler}
            disabled={scanning || map.length}
          />
        </Col>
        <Col xs={{ span: 9 }} md={{ span: 3 }} style={{ display: `${pointsPerSide === 1 && numLevels === 1 ? 'block' : 'none'}` }}>
          <InputNumber
            addonBefore='#'
            value={singlePointMultiplier}
            min={1}
            max={1000}
            onChange={e => singlePointMultiplierInputHandler(e)}
            disabled={scanning || map.length}
          />
        </Col>
      </Row>
      <Row justify="center" align="middle" style={{ margin: '10px 0' }}>
        <Col>
          {timeEstimate()}
        </Col>
      </Row>
    </>
  )
}
