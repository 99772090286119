import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import { DownloadOutlined, CheckOutlined, InfoCircleOutlined, UndoOutlined } from '@ant-design/icons';
import { Button, Tour } from 'antd';
import Results_A from '../assets/Results_A.mp4';
import Results_B from '../assets/Results_B.mp4';
import Results_C from '../assets/Results_C.png';
import Results_D from '../assets/Results_D.mp4';
import Results_E from '../assets/Results_E.mp4';
import Results_F from '../assets/Results_F.mp4';
import Results_G from '../assets/Results_G.mp4';
import Results_H from '../assets/Results_H.png';

const getIQR = (signals) => {
    let q1 = 0, q2 = 0, q3 = 0;
    if (signals.length < 4) return { q1, q2, q3 };
    const sortedSignals = signals.sort((a, b) => a - b);
    if (sortedSignals.length % 2 !== 0) {
        q2 = sortedSignals[Math.floor(sortedSignals.length / 2)];
        const lower = sortedSignals.slice(0, sortedSignals.length / 2);
        const upper = sortedSignals.slice(sortedSignals.length / 2 + 1);
        if (lower.length % 2 !== 0) {
            q1 = lower[Math.floor(lower.length / 2)];
            q3 = upper[Math.floor(upper.length / 2)];
        } else {
            q1 = (lower[(lower.length / 2) - 1] + lower[(lower.length / 2)]) / 2;
            q3 = (upper[(upper.length / 2) - 1] + upper[(upper.length / 2)]) / 2;
        }
    } else {
        q2 = (sortedSignals[(sortedSignals.length / 2) - 1] + sortedSignals[(sortedSignals.length / 2)]) / 2;
        const lower = sortedSignals.slice(0, sortedSignals.length / 2);
        const upper = sortedSignals.slice(sortedSignals.length / 2);
        if (lower.length % 2 !== 0) {
            q1 = lower[Math.floor(lower.length / 2)];
            q3 = upper[Math.floor(upper.length / 2)];
        } else {
            q1 = (lower[(lower.length / 2) - 1] + lower[(lower.length / 2)]) / 2;
            q3 = (upper[(upper.length / 2) - 1] + upper[(upper.length / 2)]) / 2;
        }
    }
    return { q1, q2, q3 };
}

const VisualResultsTable = ({ record }) => {

    const chartContainerRef = useRef(null);
    const rootID = useRef(Date.now().toString());
    const rootRef = useRef(null);
    const chartRef = useRef(null);
    const exportingRef = useRef(null);
    const [resultsTourOpen, setResultsTourOpen] = useState(false);
    const [downloadIcon, setDownloadIcon] = useState(<DownloadOutlined style={{ fontSize: '1rem' }} />);
    const [isZoomed, setIsZoomed] = useState(false);
    const startEndChangeTimeoutRef = useRef(null);

    const resultsTourSteps = [
        {
            description: 'The Scatr Series One scans many points across the sample.',
            cover: (
                <video
                    src={Results_A}
                    style={{ width: '100%' }}
                    autoPlay
                    loop
                />
            ),
            target: () => chartContainerRef.current,
        },
        {
            description: 'Each point produces a signal.',
            cover: (
                <video
                    src={Results_B}
                    style={{ width: '100%' }}
                    autoPlay
                    loop
                />
            ),
            target: () => chartContainerRef.current,
        },
        {
            description: 'Each drug has a unique signal.',
            cover: (
                <img
                    src={Results_C}
                    style={{ width: '100%' }}
                />
            ),
            target: () => chartContainerRef.current,
        },
        {
            description: 'Software matches each signal to produce results.',
            cover: (
                <video
                    src={Results_D}
                    style={{ width: '100%' }}
                    autoPlay
                    loop
                />
            ),
            target: () => chartContainerRef.current,
        },
        {
            description: 'Closer matches produce stronger signals. Pure samples will have the strongest signal.',
            cover: (
                <video
                    src={Results_E}
                    style={{ width: '100%' }}
                    autoPlay
                    loop
                />
            ),
            target: () => chartContainerRef.current,
        },
        {
            description: 'Most points are actually mixtures! Meaning each point can have multiple substances.',
            cover: (
                <video
                    src={Results_F}
                    style={{ width: '100%' }}
                    autoPlay
                    loop
                />
            ),
            target: () => chartContainerRef.current,
        },
        {
            description: 'A higher number of points scanned gives more information about the sample.',
            cover: (
                <video
                    src={Results_G}
                    style={{ width: '100%' }}
                    autoPlay
                    loop
                />
            ),
            target: () => chartContainerRef.current,
        },
        {
            description: 'The shaded rectangle represents the most likely signal strength range for each substance.',
            cover: (
                <img
                    src={Results_H}
                    style={{ width: '100%' }}
                />
            ),
            target: () => chartContainerRef.current,
        },
    ];

    useLayoutEffect(() => {

        if (rootRef.current) {
            rootRef.current.dispose();
        }

        let root = am5.Root.new(rootID.current);
        root.fps = 20;
        root._logo.dispose();

        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            layout: root.verticalLayout
        }));

        chart.set("cursor", am5xy.XYCursor.new(root, {
            behavior: "zoomX"
        }));

        let cursor = chart.get("cursor");

        cursor.lineX.setAll({
            stroke: am5.color(0x1890ff),
            strokeWidth: 3,
            strokeDasharray: []
        });

        cursor.lineY.setAll({
            visible: false
        });

        let yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: "category",
            renderer: am5xy.AxisRendererY.new(root, {
                cellStartLocation: 0.1,
                cellEndLocation: 0.9,
                inversed: true
            })
        }));

        let yRenderer = yAxis.get("renderer");

        yRenderer.labels.template.setAll({
            paddingRight: 40
        });

        yRenderer.grid.template.setAll({
            location: 0.5
        });

        let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
            min: 0,
            max: 100,
            strictMinMax: true,
            maxPrecision: 0,
            renderer: am5xy.AxisRendererX.new(root, {
                minGridDistance: 30
            })
        }));

        xAxis.children.push(am5.Label.new(root, { text: "Signal Strength", x: am5.p50, centerX: am5.p50 }));

        let series = chart.series.push(am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            baseAxis: yAxis,
            valueXField: "signal",
            valueField: "value",
            categoryYField: "category",
            calculateAggregates: true
        }));

        series.strokes.template.setAll({
            strokeOpacity: 0
        });

        let circleTemplate = am5.Template.new({});

        series.bullets.push(function () {
            return am5.Bullet.new(root, {
                locationY: 0.5,
                sprite: am5.Circle.new(root, {
                    radius: 5,
                    fillOpacity: 0.5,
                    fill: am5.color(0x1890ff)
                }, circleTemplate)
            });
        });

        // let averageSeries = chart.series.push(am5xy.LineSeries.new(root, {
        //     xAxis: xAxis,
        //     yAxis: yAxis,
        //     baseAxis: yAxis,
        //     valueXField: "average",
        //     categoryYField: "category",
        // }));

        // averageSeries.strokes.template.setAll({
        //     strokeOpacity: 0
        // });

        // averageSeries.bullets.push(function () {
        //     let graphics = am5.Rectangle.new(root, {
        //         width: 2,
        //         height: 48,
        //         centerX: am5.p50,
        //         centerY: am5.p50,
        //         fill: am5.color(0x1890ff)
        //     });

        //     return am5.Bullet.new(root, {
        //         sprite: graphics
        //     });
        // });

        let iqrSeries = chart.series.push(am5xy.ColumnSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            openValueXField: "q1",
            valueXField: "q3",
            categoryYField: "category",
            sequencedInterpolation: true,
        }));

        iqrSeries.columns.template.setAll({
            fill: am5.color(0x1890ff),
            fillOpacity: 0.3,
            stroke: am5.color("#fff"),
            strokeOpacity: 0,
            height: am5.percent(100)
        });

        let cellSize = 60;
        series.events.on("datavalidated", function (ev) {
            let series = ev.target;
            let chart = series.chart;
            let xAxis = chart.xAxes.getIndex(0);
            let yAxis = chart.yAxes.getIndex(0);
            let chartHeight = yAxis.data.length * cellSize + xAxis.height() + chart.get("paddingTop", 0) + chart.get("paddingBottom", 0);
            chart.root.dom.style.height = chartHeight + "px";
        });

        let exporting = am5plugins_exporting.Exporting.new(root, {});

        chart.zoomOutButton.set("forceHidden", true);

        chart.events.on("click", () => {
            chart.zoomOut();
        });

        xAxis.on("start", handleStartEndChange);
        xAxis.on("end", handleStartEndChange);

        function handleStartEndChange() {
            if (startEndChangeTimeoutRef.current) {
                clearTimeout(startEndChangeTimeoutRef.current);
            }
            startEndChangeTimeoutRef.current = setTimeout(function () {
                zoomEnded();
            }, 50);
        }

        function zoomEnded() {
            setIsZoomed(!(xAxis.getPrivate("selectionMin") == 0 && xAxis.getPrivate("selectionMax") == 100));
        }

        chartRef.current = chart;
        exportingRef.current = exporting;

        return () => {
            root.dispose();
        };
    }, []);

    useLayoutEffect(() => {

        if (!record?.results?.length) return;

        // const results = record.showTrace ? record.results : record.results.filter(row => row.is_trace_only === false);
        const results = record.results.filter(row => {
            return record.showTrace ? (row.substance !== 'Unknown') : (row.substance !== 'Unknown' && row.is_trace_only === false);
        })

        if (!chartRef.current || !results) return;

        let newResultsSeriesData = [];

        // for (let substance in results) {
        //     for (let i = 0; i < results[substance].length; i++) {
        //         newResultsSeriesData.push({
        //             category: substance,
        //             signal: results[substance][i]
        //         });
        //     }
        // }

        for (let i = 0; i < results.length; i++) {
            for (let j = 0; j < results[i].predictions.length; j++) {
                newResultsSeriesData.push({
                    category: results[i].substance,
                    signal: results[i].predictions[j],
                });
            }
        }

        // const averageSeriesData = [];

        // for (let substance in results) {
        //     averageSeriesData.push({
        //         category: substance,
        //         average: results[substance].reduce((a, b) => a + b, 0) / results[substance].length
        //     });
        // }

        let iqrSeriesData = [];

        // for (let substance in results) {
        //     const { q1, q2, q3 } = getIQR(results[substance]);
        //     iqrSeriesData.push({
        //         category: substance,
        //         q1,
        //         q3
        //     });
        // }

        for (let i = 0; i < results.length; i++) {
            const { q1, q2, q3 } = getIQR(results[i].predictions);
            iqrSeriesData.push({
                category: results[i].substance,
                q1,
                q3
            });
        }

        const yAxis = chartRef.current?.yAxes.getIndex(0);
        const series = chartRef.current?.series.getIndex(0);
        // const averageSeries = chartRef.current?.series.getIndex(1);
        // const iqrSeries = chartRef.current?.series.getIndex(2);
        const iqrSeries = chartRef.current?.series.getIndex(1);

        // yAxis.data.setAll(Object.keys(results).map(category => ({ category })));
        yAxis.data.setAll(results.map(row => ({ category: row.substance })));
        series.data.setAll(newResultsSeriesData);
        // averageSeries.data.setAll(averageSeriesData);
        iqrSeries.data.setAll(iqrSeriesData);

    }, [record]);

    useEffect(() => {
        const { serial } = record
        exportingRef.current.set("filePrefix", `scatr-results-serial-${serial}`);
    }, [record]);

    const handleDownload = () => {
        exportingRef.current.download("png", { quality: 1, minWidth: 3840 });
        setDownloadIcon(<CheckOutlined style={{ color: '#1677ff' }} />);
        setTimeout(() => {
            setDownloadIcon(<DownloadOutlined style={{ fontSize: '1rem' }} />);
        }, 3000);
    }

    const handleReset = () => {
        if (!chartRef.current) return;
        chartRef.current.zoomOut();
    }

    return (
        <div style={{ position: 'relative' }}>
            <div ref={chartContainerRef} id={`${rootID.current}`} style={{ width: "100%", padding: '6px 0 0 0' }}></div>
            <Button shape='circle' color="default" type="text" style={{ position: 'absolute', bottom: '0px', right: '8px' }} icon={downloadIcon} onClick={handleDownload} />
            {isZoomed && (<Button shape='circle' color="default" type="text" style={{ position: 'absolute', bottom: '0px', right: '40px' }} icon={<UndoOutlined />} onClick={handleReset} />)}
            <Button shape='circle' color="default" type="text" style={{ position: 'absolute', bottom: '0px', left: '8px' }} icon={<InfoCircleOutlined />} onClick={() => setResultsTourOpen(true)} />
            <Tour open={resultsTourOpen} onClose={() => setResultsTourOpen(false)} steps={resultsTourSteps} />
        </div>
    )
}

export default VisualResultsTable;