import React, { useEffect, useRef, useState } from "react";
import { useAtom } from 'jotai';

import { bluetoothServiceState, imageFilenameState } from '../stateManagement/commonState';
import { scanningState } from '../stateManagement/processState';
import { Progress, notification, Spin } from "antd";
import { LoadingOutlined, CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

const imageCharacteristicUUID = 'f7e2b220-c631-11ef-9130-f786fbfac8e0';

const key = 'updatable';

const BluetoothImage = () => {

    const [bluetoothService] = useAtom(bluetoothServiceState);
    const imageCharacteristicRef = useRef(null);
    const [downloadProgress, setDownloadProgress] = useState(0);

    const [scanning] = useAtom(scanningState);
    const scanningRef = useRef(false);

    const [imageFilename] = useAtom(imageFilenameState);
    const imageFilenameRef = useRef(null);

    const abortRef = useRef(false);

    const [api, contextHolder] = notification.useNotification();

    const openNotification = (description, style) => {
        api.open({
            key,
            description: description,
            duration: 0,
            placement: 'bottomRight',
            closeIcon: null,
            style: style
        });
    };

    useEffect(() => {
        scanningRef.current = scanning;
        imageFilenameRef.current = imageFilename;
    }, [scanning, imageFilename]);

    useEffect(() => {
        if (!imageCharacteristicRef.current) return;
        if (scanning) {
            abortRef.current = true;
            imageCharacteristicRef.current.addEventListener('characteristicvaluechanged', handleImageCharacteristicNotification);
            imageCharacteristicRef.current.startNotifications();
        } else {
            imageCharacteristicRef.current.stopNotifications();
            imageCharacteristicRef.current.removeEventListener('characteristicvaluechanged', handleImageCharacteristicNotification);
        }
    }, [scanning]);

    const handleImageCharacteristicNotification = async (event) => {
        try {
            const decoder = new TextDecoder('utf-8');
            const filename = decoder.decode(event.target.value);
            if (filename === imageFilenameRef.current && scanningRef.current) {
                imageCharacteristicRef.current.stopNotifications();
                imageCharacteristicRef.current.removeEventListener('characteristicvaluechanged', handleImageCharacteristicNotification);
                getImage();
            }
        } catch (error) {
            console.error('Error decoding image filename:', error);
        }
    }

    useEffect(() => {
        if (!bluetoothService) return;
        const getImageCharacteristic = async () => {
            try {
                imageCharacteristicRef.current = await bluetoothService.getCharacteristic(imageCharacteristicUUID);
                console.log(imageCharacteristicRef.current);
                await imageCharacteristicRef.current.writeValueWithResponse(new TextEncoder().encode('reset'));
                await imageCharacteristicRef.current.writeValueWithResponse(new TextEncoder().encode('delete'));
            } catch (error) {
                console.log('No image characteristic detected.');
            }
        }
        if (!imageCharacteristicRef.current) { getImageCharacteristic() };
    }, [bluetoothService]);

    useEffect(() => {
        if (downloadProgress > 0 && downloadProgress < 100) {
            openNotification(<>
                <p style={{ margin: 0 }}>{downloadProgress === 100 ? 'Image received.' : 'Receiving image...'}</p>
                <Progress percent={downloadProgress} />
            </>);
        }
    }, [downloadProgress]);

    const getImage = async () => {
        abortRef.current = false;
        await imageCharacteristicRef.current.writeValueWithResponse(new TextEncoder().encode('reset'));
        const metadataDataView = await imageCharacteristicRef.current.readValue();
        const decoder = new TextDecoder('utf-8');
        const jsonString = decoder.decode(metadataDataView);
        const metadata = JSON.parse(jsonString);
        console.log(metadata);
        let imageBuffer = new Uint8Array(metadata.numBytes);
        let currentIndex = 0;
        for (let i = 0; i < metadata.chunkCount; i++) {
            if (abortRef.current === true) break;
            setDownloadProgress(Math.round((i + 1) / metadata.chunkCount * 100));
            const chunkDataView = await imageCharacteristicRef.current.readValue();
            const chunkArray = new Uint8Array(chunkDataView.buffer);
            imageBuffer.set(chunkArray, currentIndex);
            currentIndex += chunkArray.length;
        }
        await imageCharacteristicRef.current.writeValueWithResponse(new TextEncoder().encode('delete'));
        const file = new File([imageBuffer], metadata.filename, { type: "image/jpg" });

        const formData = new FormData();
        formData.append('file', file);

        try {
            openNotification(
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Spin indicator={<LoadingOutlined spin />} size="small" />
                    <p style={{ margin: 0, marginLeft: 8 }}>Uploading image...</p>
                </div>,
                { width: 210 }
            );
            // Add back after done testing
            await fetch('/uploadImage', {
                method: 'POST',
                body: formData,
            });
            openNotification(
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CheckCircleFilled style={{ fontSize: '14px', color: '#52c41a' }} />
                    <p style={{ margin: 0, marginLeft: 8 }}>Image uploaded.</p>
                </div>,
                { width: 210 }
            );
        } catch (error) {
            console.error('uploadImage error:', error);
            openNotification(
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CloseCircleFilled style={{ fontSize: '14px', color: '#ff4d4f' }} />
                    <p style={{ margin: 0, marginLeft: 8 }}>Image upload failed.</p>
                </div>,
                { width: 210 }
            );
        } finally { setTimeout(() => { api.destroy(key) }, 3000) };
    }

    return (<>
        <style>
            {`
          .ant-notification-notice-message {
            display: none;
          }
        `}
        </style>
        {contextHolder}
    </>);
}

export default BluetoothImage;