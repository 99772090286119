import { substanceMap } from './substancesAndColors';

const benzodiazepineLabels = ['Alprazolam', 'Bromazepam', 'Bromazolam', 'Clonazepam', 'Desalkylgidazepam', 'Diazepam', 'Etizolam', 'Flubromazepam', 'Flurazepam', 'Lorazepam', 'Oxazepam', 'Temazepam', 'Triazolam'];
const xylazineLabels = ['Xylazine', 'Xylazine hydrochloride'];
const fentanylLabels = ['Acetylfentanyl', 'Carfentanil', 'Despropionyl fentanyl', 'Fentanyl', 'Furanylfentanyl', 'Parafluorofentanyl', 'Remifentanil'];
const benzodiazepineLabelsMap = new Map(benzodiazepineLabels.map((e, i) => [e, i]));
const approvedFentanylLabels = ['Caffeine', 'Dimethyl sulfone', 'Mannitol', 'Erythritol', 'Inositol', 'Sorbitol', 'Xylitol'];
const approvedXylazineLabels = ['Caffeine', 'Dimethyl sulfone', 'Mannitol', 'Erythritol', 'Inositol', 'Sorbitol', 'Xylitol', 'Acetylfentanyl', 'Carfentanil', 'Despropionyl fentanyl', 'Fentanyl', 'Furanylfentanyl', 'Parafluorofentanyl', 'Remifentanil'];
const approvedBenzodiazepineLabels = ['Caffeine', 'Dimethyl sulfone', 'Mannitol', 'Erythritol', 'Inositol', 'Sorbitol', 'Xylitol', 'Acetylfentanyl', 'Carfentanil', 'Despropionyl fentanyl', 'Fentanyl', 'Furanylfentanyl', 'Parafluorofentanyl', 'Remifentanil'];
const approvedHydromorphoneLabels = ['Lactose', 'alpha-Lactose'];

const getTraceValue = (point, label) => {
    if (fentanylLabels.includes(label)) {
        if (!approvedFentanylLabels.includes(point.label)) return null;
        return Math.max(...point.fentanylPrediction) * 100;
    }
    else if (xylazineLabels.includes(label)) {
        if (!approvedXylazineLabels.includes(point.label)) return null;
        return Math.max(...point.xylazinePrediction) * 100;
    }
    else if (benzodiazepineLabels.includes(label)) {
        if (!approvedBenzodiazepineLabels.includes(point.label)) return null;
        return point.benzodiazepinePrediction[benzodiazepineLabelsMap.get(label)] * 100;
    }
    else if (label === 'Hydromorphone') {
        if (!approvedHydromorphoneLabels.includes(point.label)) return null;
        return Math.min(...point.hydromorphonePrediction) * 100;
    } else {
        return null;
    }
}

export const computeResults = (map, traceMap) => {

    let hasUnknown = false;
    let filteredMap = map.filter(p => {
        if (p.label === 'Unknown') { hasUnknown = true; }
        return !['No Signal', 'Unknown'].includes(p.label);
    });

    const baseDetectedSubstances = [...new Set(filteredMap.map(p => p.label))];
    const traceDetectedSubstances = [...new Set(traceMap.filter(e => e.label).map(e => e.label))];
    const allDetectedSubstances = [...new Set([...baseDetectedSubstances, ...traceDetectedSubstances])];

    const newPointData = {};

    for (let i = 0; i < filteredMap.length; i++) {
        for (let j = 0; j < allDetectedSubstances.length; j++) {

            const substanceIndex = substanceMap.get(allDetectedSubstances[j]);
            let model1Value = filteredMap[i].result[1][substanceIndex] * 100;
            let model2Value = filteredMap[i].result[2][substanceIndex] * 100;

            if (allDetectedSubstances[j] === 'Fentanyl') {
                for (let k = 0; k < fentanylLabels.length; k++) {
                    const analogIndex = substanceMap.get(fentanylLabels[k]);
                    let model1AnalogValue = filteredMap[i].result[1][analogIndex] * 100;
                    let model2AnalogValue = filteredMap[i].result[2][analogIndex] * 100;
                    model1Value = model1Value < model1AnalogValue ? model1AnalogValue : model1Value;
                    model2Value = model2Value < model2AnalogValue ? model2AnalogValue : model2Value;
                }
            } else if (allDetectedSubstances[j] === 'Xylazine') {
                for (let k = 0; k < xylazineLabels.length; k++) {
                    const analogIndex = substanceMap.get(xylazineLabels[k]);
                    let model1AnalogValue = filteredMap[i].result[1][analogIndex] * 100;
                    let model2AnalogValue = filteredMap[i].result[2][analogIndex] * 100;
                    model1Value = model1Value < model1AnalogValue ? model1AnalogValue : model1Value;
                    model2Value = model2Value < model2AnalogValue ? model2AnalogValue : model2Value;
                }
            }

            const traceValue = getTraceValue(filteredMap[i], allDetectedSubstances[j]);

            const value = traceValue ? (model1Value + model2Value + traceValue) / 3 : (model1Value + model2Value) / 2;

            if (!newPointData[allDetectedSubstances[j]]) newPointData[allDetectedSubstances[j]] = [];
            newPointData[allDetectedSubstances[j]].push(value);
        }
    }

    if (hasUnknown) newPointData['Unknown'] = [];
    
    const resultsArray = Object.entries(newPointData).map(([key, predictions]) => {
        return { substance: key, predictions: predictions, is_trace_only: ![...baseDetectedSubstances, 'Unknown'].includes(key) };
    });

    return resultsArray;
}