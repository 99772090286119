import { Input, Modal, List, Button, Tooltip } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import { useAtom } from 'jotai';
import { deviceInfoState, wifiCharacteristicState } from '../stateManagement/commonState';
import { CheckOutlined } from '@ant-design/icons';
import { Buffer } from 'buffer';
import Lottie from "lottie-react";
import SignalWifi1BarIcon from '@mui/icons-material/SignalWifi1Bar';
import SignalWifi2BarIcon from '@mui/icons-material/SignalWifi2Bar';
import SignalWifi3BarIcon from '@mui/icons-material/SignalWifi3Bar';
import SignalWifi4BarIcon from '@mui/icons-material/SignalWifi4Bar';
import SignalWifi1BarLockIcon from '@mui/icons-material/SignalWifi1BarLock';
import SignalWifi2BarLockIcon from '@mui/icons-material/SignalWifi2BarLock';
import SignalWifi3BarLockIcon from '@mui/icons-material/SignalWifi3BarLock';
import SignalWifi4BarLockIcon from '@mui/icons-material/SignalWifi4BarLock';
import wifiLoadingAnimation from '../images/wifiLoading.json';
import successAnimation from '../images/success.json';
import errorAnimation from '../images/error.json';
import wifiSearchAnimation from '../images/wifiSearch.json';

import { writeToDevice, readEEPROM } from '../utils/bluetoothLogic';
import { arrayBufferToBufferCycle } from '../utils/helpers';
import { parseEEPROM } from '../utils/parseEEPROM';
import { updateDeviceNetworkStatusInDB } from '../utils/dbHelpers';

const WifiConfigModal = ({ isModalOpen, setWifiConfigModalOpen, bluetoothState, characteristic }) => {
  const [deviceInfo, setDeviceInfo] = useAtom(deviceInfoState);
  const [availableNetworks, setAvailableNetworks] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [state, setState] = useState('selectingNetwork');
  const [wifiCharacteristic] = useAtom(wifiCharacteristicState);
  const manualNetworkCredentialsRef = useRef({ name: '', password: '' });

  const onCancel = () => {
    setWifiConfigModalOpen(false);
  }

  const handleWifiCharacteristicNotification = (event) => {
    const decoder = new TextDecoder('utf-8');
    const jsonString = decoder.decode(event.target.value);
    const network = JSON.parse(jsonString);
    if (network === 'END') {
      wifiCharacteristic.stopNotifications();
      wifiCharacteristic.removeEventListener('characteristicvaluechanged', handleWifiCharacteristicNotification);
    } else {
      setAvailableNetworks((prevNetworks) => [...prevNetworks, network]);
    }
  }

  useEffect(() => {
    if (bluetoothState === 'Disconnected') {
      setState('error');
    }
  }, [bluetoothState]);

  useEffect(() => {
    if (availableNetworks.length > 0) {
      setState('selectingNetwork');
    }
  }, [availableNetworks]);

  useEffect(() => {
    if (!wifiCharacteristic) {
      setState('manual');
    } else {
      if (isModalOpen) {
        setState('searching');
      }
      if (isModalOpen && wifiCharacteristic) {
        wifiCharacteristic.addEventListener('characteristicvaluechanged', handleWifiCharacteristicNotification);
        wifiCharacteristic.startNotifications();
      } else if (!isModalOpen && wifiCharacteristic) {
        if (wifiCharacteristic.service.device.gatt.connected) {
          wifiCharacteristic.stopNotifications();
          wifiCharacteristic.removeEventListener('characteristicvaluechanged', handleWifiCharacteristicNotification);
        }
      }
      if (!isModalOpen) {
        setAvailableNetworks([]);
      }
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (selectedNetwork) {
      if (selectedNetwork.authentication_type === 'Open') {
        connectToNetwork(selectedNetwork)
      } else {
        setState('authenticating');
      }
    }
  }, [selectedNetwork]);

  useEffect(() => {
    if (state === 'selectingNetwork') {
      setSelectedNetwork(null);
      if (!wifiCharacteristic?.service?.device?.gatt?.connected) {
        setWifiConfigModalOpen(false);
      }
    }
  }, [state]);

  const getSortedNetworks = (availableNetworks) => {
    return [
      ...availableNetworks.filter(n => n.is_connected === true),
      ...availableNetworks.filter(n => n.is_connected === false),
    ];
  };

  const pause = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const connectToNetwork = async network => {
    const requestID = Date.now();
    network.requestID = requestID;
    setState('connecting');
    let isConnected;
    let networkJSON = JSON.stringify(network);
    let buf = Buffer.from(networkJSON);
    try {
      await wifiCharacteristic.writeValueWithResponse(buf);
    } catch (error) {
      console.error('Error writing value to characteristic:', error);
      isConnected = false;
      return;
    }

    let connectionRequest = { id: null, status: null };

    for (let i = 0; i < 30; i++) {
      await pause(1000);
      const readResponse = await wifiCharacteristic.readValue();
      const decoder = new TextDecoder('utf-8');
      const jsonString = decoder.decode(readResponse);
      connectionRequest = JSON.parse(jsonString);
      if (connectionRequest.status !== 'Connecting') {
        break;
      }
    }

    if (connectionRequest.status === 'Connected' && connectionRequest.id === requestID) {
      setDeviceInfo({ ...deviceInfo, device_network_status: 1 });
      setState('success');
      setTimeout(() => {
        setAvailableNetworks((prevNetworks) => {
          return prevNetworks.map(n => {
            if (n.network_name === network.network_name) {
              return { ...n, is_connected: true };
            }
            return { ...n, is_connected: false };
          });
        })
        setState('selectingNetwork');
      }, 3000);
    } else {
      setDeviceInfo({ ...deviceInfo, device_network_status: 0 }); //Assuming failed and no auto connect to previous network
      setState('error');
    }
  }

  const disconnectFromNetwork = () => {
    // Device might auto connect to another known network. Need to check before setting network status.
    setDeviceInfo({ ...deviceInfo, device_network_status: 0 });
  }

  const manualConnectToNetwork = async network => {
    setState('connecting');
    await writeToDevice(characteristic, [
      ['connectToWifi', [manualNetworkCredentialsRef.current.name, manualNetworkCredentialsRef.current.password]],
      ['executeCommands']
    ]);
    let currentDeviceInfo = null;
    for (let i = 0; i < 5; i++) {
      await new Promise(resolve => setTimeout(resolve, 3000));
      let eepromData = await readEEPROM(characteristic);
      let eepromDataBuffer = arrayBufferToBufferCycle(eepromData.buffer);
      currentDeviceInfo = { ...parseEEPROM(eepromDataBuffer) }
      if (currentDeviceInfo.device_network_status) {
        break;
      }
    }
    await updateDeviceNetworkStatusInDB(currentDeviceInfo.device_id, currentDeviceInfo.device_network_status);
    setDeviceInfo(currentDeviceInfo);
    if (currentDeviceInfo.device_network_status) {
      setState('success');
      await new Promise(resolve => setTimeout(resolve, 3000));
      setWifiConfigModalOpen(false);
    } else {
      setState('error');
    }
  }

  return (
    <>
      <Modal
        title='Wi-Fi'
        open={isModalOpen}
        onCancel={onCancel}
        footer={[]}
      >
        {state === 'selectingNetwork' &&
          <List
            style={{ width: '100%', height: '328px', overflow: 'auto' }}
            split={false}
            dataSource={getSortedNetworks(availableNetworks)}
            renderItem={(network, index) => {
              const isCurrentNetwork = network?.is_connected;
              let wifiIcon;
              if (network.signal > -50) {
                wifiIcon = network.authentication_type === 'Open' ? <SignalWifi4BarIcon /> : <SignalWifi4BarLockIcon />;
              } else if (network.signal > -60) {
                wifiIcon = network.authentication_type === 'Open' ? <SignalWifi3BarIcon /> : <SignalWifi3BarLockIcon />;
              } else if (network.signal > -67) {
                wifiIcon = network.authentication_type === 'Open' ? <SignalWifi2BarIcon /> : <SignalWifi2BarLockIcon />;
              } else {
                wifiIcon = network.authentication_type === 'Open' ? <SignalWifi1BarIcon /> : <SignalWifi1BarLockIcon />;
              }
              return (
                <List.Item style={{ position: 'relative', padding: `${availableNetworks.length > 4 ? '4px 4px 4px 0' : '4px 0'}` }}>
                  <Button
                    style={{
                      width: '100%',
                      height: '64px',
                      padding: '4px 16px',
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row',
                      transitionDuration: '0s',
                      backgroundColor: isCurrentNetwork ? '#e6f7ff' : 'transparent',
                      border: isCurrentNetwork ? '1px solid #1890ff' : 'none',
                    }}
                    icon={wifiIcon}
                    onClick={_ => { isCurrentNetwork ? disconnectFromNetwork() : setSelectedNetwork(network); }}
                  >
                    <div>{network.network_name}</div>
                  </Button>
                  {isCurrentNetwork &&
                    <div style={{ position: 'absolute', right: '16px' }}>
                      <Button type="ghost" shape="circle" icon={<CheckOutlined />} />
                    </div>
                  }
                </List.Item>
              )
            }
            }
          />
        }
        {state === 'authenticating' &&
          <>
            <div style={{ margin: '15px 0' }}>The Wi-Fi network "{selectedNetwork?.network_name}" requires authentication.</div>
            <div style={{ margin: '15px 0' }}>
              {selectedNetwork?.authentication_type === 'Login' &&
                <Input
                  variant="filled"
                  addonBefore={<div style={{ width: 90 }}>Username</div>}
                  onChange={(e) => { setSelectedNetwork({ ...selectedNetwork, username: e.target.value }) }}
                  styles={{ textAlign: 'right', borderBottom: '2px solid black' }}
                />
              }
              <Input.Password
                variant="filled"
                addonBefore={<div style={{ width: 90 }}>Password</div>}
                onChange={(e) => { setSelectedNetwork({ ...selectedNetwork, password: e.target.value }) }}
                onPressEnter={_ => connectToNetwork(selectedNetwork)}
              />
            </div>
            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={() => { setState('selectingNetwork'); }} style={{ marginRight: '8px' }}>Cancel</Button>
              <Button type="primary" onClick={_ => connectToNetwork(selectedNetwork)}>OK</Button>
            </div>
          </>
        }
        {state === 'searching' &&
          <div style={{ width: '150px', margin: '0 auto', textAlign: 'center' }}>
            <Lottie animationData={wifiSearchAnimation} loop={true} speed={0.5} />
            <p>Finding networks...</p>
          </div>
        }
        {state === 'connecting' &&
          <div style={{ width: '150px', margin: '0 auto', textAlign: 'center' }}>
            <Lottie animationData={wifiLoadingAnimation} loop={true} />
            <p>Connecting...</p>
          </div>
        }
        {state === 'success' &&
          <div style={{ width: '150px', margin: '0 auto', textAlign: 'center' }}>
            <Lottie animationData={successAnimation} loop={false} />
            <div style={{ margin: '-15px 0 15px 0' }}>Connected</div>
          </div>
        }
        {state === 'error' &&
          <div style={{ width: '150px', margin: '0 auto', textAlign: 'center' }}>
            <Lottie animationData={errorAnimation} loop={false} />
            <div style={{ margin: '-15px 0 15px 0' }}>Failed to connect.</div>
            <Button type="primary" ghost onClick={_ => {
              if (wifiCharacteristic) {
                setState('selectingNetwork');
              } else {
                setState('manual');
              }
            }}>Try Again</Button>
          </div>
        }
        {state === 'manual' &&
          <>
            <div style={{ margin: '15px 0' }}>
              <Input
                variant="filled"
                addonBefore={<div style={{ width: 120 }}>Network Name</div>}
                onChange={(e) => { manualNetworkCredentialsRef.current.name = e.target.value; }}
                styles={{ textAlign: 'right', borderBottom: '2px solid black' }}
              />
              <Input.Password
                variant="filled"
                addonBefore={<div style={{ width: 120 }}>Password</div>}
                onChange={(e) => { manualNetworkCredentialsRef.current.password = e.target.value; }}
                onPressEnter={_ => manualConnectToNetwork(selectedNetwork)}
              />
            </div>
            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={_ => { onCancel(); }} style={{ marginRight: '8px' }}>Cancel</Button>
              <Button type="primary" onClick={_ => manualConnectToNetwork()}>OK</Button>
            </div>
          </>
        }
      </Modal>
    </>
  );
};

export default WifiConfigModal;