import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import awsconfig from './aws-exports';
import { Amplify } from 'aws-amplify';
import { Heading } from '@aws-amplify/ui-react';
import { Authenticator, useTheme } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: process.env.NODE_ENV === "production" ? "https://79f4cfa5d73a8a4778792c70287d88f4@o4506440240070656.ingest.sentry.io/4506440247017472" : null,
  includeLocalVariables: true,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
    }),
    Sentry.replayCanvasIntegration()
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

Amplify.configure(awsconfig);

const components = {
  SignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={4}
        >
          Sign in
        </Heading>
      );
    }
  }
}

const AuthStateApp = () => {
  return (
    <Authenticator hideSignUp={true} components={components}>
      {({ signOut }) => (<App signOut={signOut} />)}
    </Authenticator>
  )
}

try {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(<AuthStateApp />);
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/service-worker.js').then(registration => { }).catch(registrationError => {
        console.error('SW registration failed: ', registrationError);
      });
    });
  }
} catch (e) {
  Sentry.captureException(e);
}

