import React, { useEffect, useRef } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const ThermalChart = ({ thermalData }) => {

    const chartRef = useRef(null);

    useEffect(() => {
        if (chartRef.current) {
            const processedData = thermalData.map(e => ({
                timestamp: new Date(e[0]).getTime(),
                temperature: e[1] / 100
            }));
            chartRef.current?.series.getIndex(0).data.setAll(processedData);
        }
    }, [thermalData]);

    useEffect(() => {

        let root = am5.Root.new("thermalChart");
        root.fps = 15;
        root._logo.dispose();

        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            layout: root.verticalLayout
        }));

        chart.set("cursor", am5xy.XYCursor.new(root, {
            behavior: "zoomX"
        }));

        let cursor = chart.get("cursor");

        cursor.lineX.setAll({
            stroke: am5.color(0x1890ff),
            strokeWidth: 3,
            strokeDasharray: []
        });

        cursor.lineY.setAll({
            visible: false
        });

        let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
            baseInterval: { timeUnit: "millisecond", count: 200 },
            renderer: am5xy.AxisRendererX.new(root, {}),
            tooltipDateFormat: "yyyy-MM-dd HH:mm:ss"
        }));

        xAxis.children.push(am5.Label.new(root, { text: "Time", x: am5.p50, centerX: am5.p50 }));

        let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {}),
            tooltipText: "{valueY}"
        }));

        yAxis.children.moveValue(am5.Label.new(root, { text: "Temperature (°C)", rotation: -90, y: am5.p50, centerX: am5.p50 }), 0);

        let series = chart.series.push(am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "temperature",
            valueXField: "timestamp",
            tooltip: am5.Tooltip.new(root, {
                pointerOrientation: "horizontal",
                labelText: "{valueY}°C\n{valueX.formatDate()}"
            })
        }));

        // const dummyData = [
        //     { timestamp: new Date().getTime() - 5000, temperature: 22 },
        //     { timestamp: new Date().getTime() - 4000, temperature: 23 },
        //     { timestamp: new Date().getTime() - 3000, temperature: 21 },
        //     { timestamp: new Date().getTime() - 2000, temperature: 24 },
        //     { timestamp: new Date().getTime() - 1000, temperature: 23 },
        //     { timestamp: new Date().getTime(), temperature: 25 }
        // ];

        // series.data.setAll(dummyData);

        chartRef.current = chart;

        return () => {
            root.dispose();
        };
    }, []);

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div id="thermalChart" style={{ width: "100%", height: '300px' }}></div>
        </div>
    );
};

export default ThermalChart;