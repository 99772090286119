import React, { useState, useRef } from "react";
import { Button, Descriptions, Switch, Empty } from "antd"
import { CloseOutlined } from '@ant-design/icons';
import { Rnd } from "react-rnd";
import { useAtom } from "jotai";
import { isTestInfoModalOpenState } from "../stateManagement/commonState";
import useResizeObserver from '@react-hook/resize-observer';
import VisualResultsTable from "./VisualResultsTable";

const TestInfoModal = ({ record, handleIncludeTraceSwitch }) => {

    const [isTestInfoModalOpen, setIsTestInfoModalOpen] = useAtom(isTestInfoModalOpenState);
    const [testInfoWindowState, setTestInfoWindowState] = useState({
        width: window.innerWidth > 1000 ? 700 : window.innerWidth * 0.55,
        height: '300px',
        x: window.innerWidth > 1000 ? window.innerWidth / 2 - 212 : window.innerWidth - (window.innerWidth * 0.6) - 12,
        y: 16,
    });
    const descriptionsComponentRef = useRef(null);

    useResizeObserver(descriptionsComponentRef, (entry) => {
        setTestInfoWindowState(prevState => ({
            ...prevState,
            height: entry.contentRect.height
        }));

    });

    return (
        <>
            {isTestInfoModalOpen && (
                <div style={{
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    zIndex: 100,
                    pointerEvents: 'none',
                }}
                >
                    <Rnd
                        style={{
                            backgroundColor: '#fafafa',
                            pointerEvents: 'auto',
                            borderRadius: '8px',
                            position: 'relative',
                            boxShadow: `0 1px 2px rgba(0,0,0,0.08), 
                                        0 2px 4px rgba(0,0,0,0.08), 
                                        0 4px 8px rgba(0,0,0,0.08), 
                                        0 8px 16px rgba(0,0,0,0.08),
                                        0 16px 32px rgba(0,0,0,0.08), 
                                        0 32px 64px rgba(0,0,0,0.08)`
                        }}
                        size={{ width: testInfoWindowState.width, height: testInfoWindowState.height }}
                        position={{ x: testInfoWindowState.x, y: testInfoWindowState.y }}
                        enableResizing={{ top: false, right: true, bottom: false, left: true, topRight: false, bottomRight: false, bottomLeft: false, topLeft: false }}
                        onDragStop={(e, d) => {
                            setTestInfoWindowState(prev => ({ ...prev, x: d.x, y: d.y }))
                        }}
                        onResize={(e, direction, ref, delta, position) => {
                            setTestInfoWindowState(prev => ({
                                ...prev,
                                width: ref.style.width,
                                height: ref.style.height,
                                ...position
                            }));
                        }}
                        minWidth={450}
                        maxWidth={875}
                        dragHandleClassName="drag-handle"
                    >
                        <Button
                            type="primary"
                            danger
                            shape="circle"
                            size="small"
                            icon={<CloseOutlined style={{ fontSize: '9px' }} />}
                            onClick={() => {
                                setIsTestInfoModalOpen(false);
                                setTestInfoWindowState({
                                    width: window.innerWidth > 1000 ? 700 : window.innerWidth * 0.55,
                                    height: '300px',
                                    x: window.innerWidth > 1000 ? window.innerWidth / 2 - 212 : window.innerWidth - (window.innerWidth * 0.6) - 12,
                                    y: 16,
                                });
                            }}
                            style={{
                                position: 'fixed',
                                top: '13px',
                                right: '21px',
                                minWidth: '15px',
                                width: '15px',
                                height: '15px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                zIndex: 103,
                            }}
                            onMouseMove={(e) => { e.stopPropagation(); }}
                        />
                        <div ref={descriptionsComponentRef}>
                            <Descriptions bordered={true} column={1} size="small">
                                <Descriptions.Item label="Serial" labelStyle={{ width: '30px' }} style={{ cursor: 'move' }} className="drag-handle">
                                    {record && record.serial}
                                </Descriptions.Item>
                                <Descriptions.Item label="Results">
                                    {
                                        (record?.results && record?.results?.length > 0) ?
                                            <div style={{ position: 'relative' }}>
                                                <Switch type="primary" size="small" ghost checkedChildren="Trace" unCheckedChildren="Trace" style={{ position: 'absolute', top: '0px', right: '21px', zIndex: 2 }}
                                                    value={record.showTrace} onClick={handleIncludeTraceSwitch} />
                                                <VisualResultsTable record={record} />
                                            </div>
                                            : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"No Results"}
                                                style={{ height: '145px', margin: '0', display: 'flex', flexDirection: 'column', justifyContent: 'center' }} />
                                    }
                                </Descriptions.Item>
                            </Descriptions>
                        </div>
                    </Rnd>
                </div>
            )}
        </>
    )
}

export default TestInfoModal;