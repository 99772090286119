import { Buffer } from 'buffer';
const bluetoothDeviceServiceUUID = '9bdc05d5-78ac-4fe9-98ee-ce06e165ee6b';
const bluetoothDeviceCharacteristic = 'b4f899e7-39cb-4ac0-8533-61a274f113a9';
const wifiCharacteristicUUID = 'ca37fd36-e7fe-4257-bf3f-75a51be3361b';

export const connectButtonHandler = async (onDisconnect, message, key) => {
  let bluetoothService;
  let bluetoothDevice;
  let characteristic;
  let wifiCharacteristic;
  await navigator.bluetooth.requestDevice({ filters: [{ services: [bluetoothDeviceServiceUUID] }] })
    .then(device => {
      message.loading({ content: 'Connecting to Series One', key, duration: 0 });
      bluetoothDevice = device;
      bluetoothDevice.addEventListener('gattserverdisconnected', onDisconnect);
      return device.gatt.connect();
    })
    .then(server => {
      return server.getPrimaryService(bluetoothDeviceServiceUUID);
    })
    .then(async (service) => {
      bluetoothService = service;
      try {
        characteristic = await service.getCharacteristic(bluetoothDeviceCharacteristic);
        wifiCharacteristic = await service.getCharacteristic(wifiCharacteristicUUID).catch(() => null);
        if (!wifiCharacteristic) { console.log('No wifi characteristic detected.'); }
      } catch (error) {
        console.error('Error obtaining characteristics:', error);
      }
    })
    .catch(error => {
      console.error('Did not connect to Series One.', error);
    });
  return [bluetoothDevice, characteristic, wifiCharacteristic, bluetoothService];
}

export const subscribeToCharacteristic = async (characteristic, bluetoothNotificationHandler) => {
  let status;
  if (characteristic) {
    await characteristic.startNotifications().then(() => {
      characteristic.addEventListener('characteristicvaluechanged', bluetoothNotificationHandler);
      status = 'Connected';
    });
  }
  else {
    status = 'Disconnected';
  }
  return status;
}

export const disconnectBluetoothDevice = async (bluetoothDevice) => {
  if (!bluetoothDevice) {
    return;
  }
  if (bluetoothDevice.gatt.connected) {
    await bluetoothDevice.gatt.disconnect();
  }
}

const generateCommandMessage = (command, data) => {
  let message = [];
  switch (command) {
    case 'sendCoordinates':
      let xyzArray = data.flat();
      for (let i = 0; i < xyzArray.length; i += 50) {
        message.push([...xyzArray.slice(i, i + 50), 4000000001]);
      }
      break;
    case 'scanCoordinates':
      message.push([4000000002]);
      break;
    case 'stopScan':
      message.push([4000000003]);
      break;
    case 'setIntegrationTime':
      message.push([data, 4000000004]);
      break;
    case 'setLaserPower':
      message.push([data, 4000000005]);
      break;
    case 'move':
      message.push([...data, 4000000006]);
      break;
    case 'setScansPerPoint':
      message.push([data, 4000000007]);
      break;
    case 'laserON':
      message.push([4000000008]);
      break;
    case 'laserOFF':
      message.push([4000000009]);
      break;
    case 'clearCoordiantes':
      message.push([4000000010]);
      break;
    case 'setStageAccelerationAndSpeedXY':
      message.push([...data, 4000000011]);
      break;
    case 'setStageAccelerationAndSpeedZ':
      message.push([...data, 4000000012]);
      break;
    case 'executeCommands':
      message.push([4000000013]);
      break;
    case 'connectToWifi':
      message.push([...data, 4000000014]);
      break;
    case 'captureImage':
      message.push([...data, 4000000015]);
      break;
    default:
      break;
  }

  return message;
}

export const writeToDevice = async (characteristic, commands) => {
  return new Promise(async (resolve, reject) => {
    let messages = [];
    for (let i = 0; i < commands.length; i++) {
      messages.push(...generateCommandMessage(commands[i][0], commands[i][1]));
    }
    let messageBatches = [[]];
    let currentBatchIndex = 0;
    for (let i = 0; i < messages.length; i++) {
      if (messageBatches[currentBatchIndex].length + messages[i].length > 50) {
        currentBatchIndex = messageBatches.push([]) - 1;
      }
      messageBatches[currentBatchIndex].push(...messages[i]);
    }
    for (let i = 0; i < messageBatches.length; i++) {
      let messageBuffer;
      // let Uint32View = new Uint32Array(messageBatches[i]);
      // let Uint8View = new Uint8Array(Uint32View.buffer);
      let Uint8View = new TextEncoder('utf-8').encode(messageBatches[i]);
      messageBuffer = Buffer.from(Uint8View);
      try {
        await characteristic.writeValue(messageBuffer);
      } catch (error) {
        console.error(error);
        await new Promise(resolve => setTimeout(resolve, 100));
        await characteristic.writeValue(messageBuffer);
      }
    }
    // for (let i = 0; i < messageBuffer.length; i += 500) {
    //   await characteristic.writeValue(messageBuffer.subarray(i, i + 500));
    // }
    resolve();
  });
}

export const readEEPROM = async (characteristic) => {
  let eePromData
  try {
    eePromData = await characteristic.readValue();
    return eePromData;
  } catch (reason) {
    console.error(reason);
  }
}