import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useAtom } from 'jotai';
import { bluetoothServiceState, showArchivedScansState } from '../stateManagement/commonState';
import { scanningState } from '../stateManagement/processState';
import { uploadTemperatureData, getTemperatureData, getArchivedTemperatureData } from "../utils/dbHelpers";
import ThermalChart from "./ThermalChart";
import { showChartsFlag } from '../stateManagement/actionButtonState';

const thermalCharacteristicUUID = '072ee740-3fb0-44b9-87fa-14d5985d6cbd';

const Thermal = ({ currentMapID }) => {

    const thermalCharacteristicRef = useRef(null);
    const thermalDataRef = useRef([]);
    const scanningRef = useRef(false);
    const currentMapIDRef = useRef(null);
    const errorCountRef = useRef(0);

    const [bluetoothService] = useAtom(bluetoothServiceState);
    const [scanning] = useAtom(scanningState);
    const [thermalData, setThermalData] = useState([]);
    const [showArchived] = useAtom(showArchivedScansState);
    const [showCharts] = useAtom(showChartsFlag);

    const readThermalData = async () => {
        if (!thermalCharacteristicRef.current) return;
        try {
            const value = await thermalCharacteristicRef.current.readValue();
            const receivedArray = new Uint8Array(value.buffer);
            const dataView = new DataView(receivedArray.buffer);
            const decodedTimestamp = Number(dataView.getBigUint64(0, true));
            const decodedTemperature = dataView.getInt16(8, true);

            decodedTimestamp ? errorCountRef.current = 0 : errorCountRef.current++
            if (decodedTimestamp) {
                setThermalData(prevThermalData => [...prevThermalData, [decodedTimestamp, decodedTemperature]]);
            }
            if (scanningRef.current && errorCountRef.current < 3) {
                setTimeout(() => readThermalData(), 500);
            } else {
                console.error('Thermal camera error detected.'); // This is wrong logic here
                errorCountRef.current = 0;
            }
        } catch (error) {
            console.error("Error reading characteristic value:", error);
        }
    };

    useEffect(() => {
        thermalDataRef.current = thermalData;
    }, [thermalData]);

    useEffect(() => {
        currentMapIDRef.current = currentMapID;
        (async () => {
            if (!currentMapID) {
                setThermalData([]);
                return;
            };
            const thermalDataFromDB = showArchived ? await getArchivedTemperatureData(currentMapID) : await getTemperatureData(currentMapID);
            if (thermalDataFromDB?.timestamps && thermalDataFromDB?.temperatures) {
                setThermalData(thermalDataFromDB?.timestamps.map((timestamp, index) => [Number(timestamp), thermalDataFromDB.temperatures[index]]));
            }
        })();
    }, [currentMapID]);

    useEffect(() => {
        if (!bluetoothService) return;
        (async () => {
            thermalCharacteristicRef.current = await bluetoothService.getCharacteristic(thermalCharacteristicUUID).catch(() => null);
            if (!thermalCharacteristicRef.current) { console.log('No thermal characteristic detected.'); }
        })();
    }, [bluetoothService]);

    useEffect(() => {
        scanningRef.current = scanning;
        if (!thermalCharacteristicRef.current) return;
        if (scanning) {
            setThermalData([]);
            readThermalData();
        } else {
            if (thermalData.length > 0) {
                uploadTemperatureData({ map_id: currentMapIDRef.current, timestamps: thermalDataRef.current.map(e => e[0]), temperatures: thermalDataRef.current.map(e => e[1]) });
            }
        }
    }, [scanning]);

    return (showCharts ? <ThermalChart thermalData={thermalData} /> : null);
};

export default Thermal;