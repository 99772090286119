import { atom } from 'jotai';

export const defaultCenter = { x: 12.75, y: 13.65, z: 14.5 };

export const minLaserPowerState = atom(450);

export const maxLaserPowerState = atom(450);

export const minIntegrationTimeState = atom(2000);

export const maxIntegrationTimeState = atom(2000);

export const classificationThresholdState = atom(0.1);

export const centerState = atom(defaultCenter);