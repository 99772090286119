import { atom } from 'jotai';

export const defaultCalibrationInfo = {
    calibrate: true,
    calibrating: false,
    firstXYCalibration: {
        active: false,
        data: [],
        settings: {
            pointsPerSide: 6,
            numLevels: 1,
            scanWidth: 4,
            laserPower: 450,
            integrationTime: 500,
            signalThreshold: 150
        },
        calculated: {
            center_x: null,
            center_y: null,
            scanWidth: null,
        }
    },
    firstHeightCalibration: {
        active: false,
        data: [],
        settings: {
            pointsPerSide: 1,
            numLevels: 11,
            scanHeight: 2,
            center_z: 14.25,
        },
        calculated: {
            center_z: null,
        }
    }
}

export const scanningState = atom(false);

export const scanHeightState = atom(1);

export const scanWidthState = atom(5);

export const pointsPerSideState = atom(13);

export const numLevelsState = atom(1);

export const numPointsState = atom(Math.pow(13, 2));

export const singlePointMultiplierState = atom(1);

export const currentPathState = atom([]);

export const calibrationInfoState = atom(defaultCalibrationInfo);

export const scanTypeState = atom('standard');