import React, { useState, useEffect, useRef } from 'react';
import { useAtom } from 'jotai';
import { Button, Tooltip, Typography, message } from 'antd';
import { cloneDeep } from 'lodash';
import { BgColorsOutlined, CompressOutlined, ExpandOutlined } from '@ant-design/icons';
import MappingGrid from './MappingGrid';
import SpectraLineChart from './SpectraLineChart';
import IntensityScatterChart from './IntensityScatterChart';
import { getColors } from '../utils/substancesAndColors';
import './PrimaryCharts.css';
import {
    colorsState
} from '../stateManagement/graphState';
import { showChartsFlag } from '../stateManagement/actionButtonState';
import { scanningState, calibrationInfoState } from '../stateManagement/processState';
import { mapState } from '../stateManagement/commonState';
import Thermal from './Thermal';


const { Text } = Typography;

const PrimaryCharts = ({ currentSerial, currentMapID }) => {
    const [linechartSpectrum, setLinechartSpectrum] = useState({
        serial: null,
        index: null,
        rawSpectrum: new Array(500).fill(null).map(_ => 0),
        badPixelCorrectedSpectrum: new Array(500).fill(null).map(_ => 0),
        baselineCorrectedSpectrum: new Array(500).fill(null).map(_ => 0),
        intensityCorrectedSpectrum: new Array(500).fill(null).map(_ => 0),
        smoothedSpectrum: new Array(500).fill(null).map(_ => 0),
        secondaryBaselineCorrectedSpectrum: new Array(500).fill(null).map(_ => 0),
        normalizedSpectrum: new Array(500).fill(null).map(_ => 0),
        interpolatedSpectrum: new Array(2100).fill(null).map(_ => 0),
        processedSpectrum: new Array(2100).fill(null).map(_ => 0),
        integrationTime: null
    });
    const [, setColors] = useAtom(colorsState);
    const [wavenumberFilterIndex, setWavenumberFilterIndex] = useState(null);
    const [map] = useAtom(mapState);
    const [showCharts] = useAtom(showChartsFlag);
    const mapSerial = useRef(null);
    const [scanning] = useAtom(scanningState);
    const [calibrationInfo, setCalibrationInfo] = useAtom(calibrationInfoState);

    useEffect(() => {
        mapSerial.current = currentSerial;
    }, [currentSerial]);

    const updateSpectrumLineChart = e => {
        e.serial = mapSerial.current;
        setLinechartSpectrum(e);
    }

    const changeColors = _ => {
        if (map.length > 0) {
            setColors(getColors());
        } else {
            message.info({ content: `No data to change colors`, duration: 1.5 });
        }
    }

    const updateWavenumberFilter = wavenumberIndex => {
        setWavenumberFilterIndex(wavenumberIndex);
    }

    const toggleCalibration = () => {
        if (!scanning) {
            let newCalibrationInfo = cloneDeep(calibrationInfo);
            newCalibrationInfo.calibrate = !calibrationInfo.calibrate;
            setCalibrationInfo(newCalibrationInfo);
            if (newCalibrationInfo.calibrate) {
                message.success({ content: `Pre-scan calibration enabled`, duration: 3 });
            } else {
                message.info({ content: `Pre-scan calibration disabled`, duration: 3 });
            }
        } else {
            message.warning({ content: `Cannot toggle calibration settings while scanning`, duration: 3 });
        }
    }

    return (
        <div style={{ position: `relative` }}>
            <Tooltip title={`${calibrationInfo.calibrate ? 'Disable calibration' : 'Enable calibration'}`}>
                <Button icon={calibrationInfo.calibrate ? <CompressOutlined /> : <ExpandOutlined />} onClick={toggleCalibration} style={{ float: 'left', marginLeft: '15%', zIndex: 2 }} disabled={scanning}></Button>
            </Tooltip>
            {currentSerial !== null &&
                <Text style={{ position: `absolute`, left: `50%`, transform: `translateX(-50%)`, top: `15px` }}>
                    Serial: {currentSerial}
                </Text>
            }
            <Button
                icon={<BgColorsOutlined />}
                onClick={changeColors}
                disabled={scanning}
                style={{ float: 'right', marginRight: '15%', zIndex: 2 }}
            />
            <MappingGrid updateSpectrumLineChart={updateSpectrumLineChart} wavenumberFilterIndex={wavenumberFilterIndex} />
            {showCharts &&
                <>
                    <SpectraLineChart
                        linechartSpectrum={linechartSpectrum}
                        updateWavenumberFilter={updateWavenumberFilter}
                    />
                    <IntensityScatterChart
                        updateSpectrumLineChart={updateSpectrumLineChart}
                    />
                </>}
            <div style={{
                display: showCharts ? 'block' : 'none',
                opacity: showCharts ? 1 : 0,
                visibility: showCharts ? 'visible' : 'hidden',
                transition: 'opacity 1s ease, visibility 1s ease',
            }}>
                <Thermal currentMapID={currentMapID} />
            </div>
        </div>
    )
}

export default PrimaryCharts
