import React, { useState, useRef, useEffect } from 'react';
import { cloneDeep } from 'lodash';
import { Auth } from 'aws-amplify';
import ClientUploads from './components/ClientUploads';
import PrimaryCharts from './components/PrimaryCharts';
import { DeviceInfoTable } from './components/DeviceInfoTable';
import classes from './App.module.css';
import 'antd/dist/reset.css';
import { SettingsButtons } from './components/SettingsButtons';
import { useAtom } from 'jotai';
import Lottie from "lottie-react";
import loadingAnimation from './images/loading.json';
import lineSwirlLoadAnimation from './images/lineSwirlLoad.json';
import {
  minLaserPowerState,
  maxLaserPowerState,
  minIntegrationTimeState,
  maxIntegrationTimeState,
  defaultCenter,
  centerState
} from './stateManagement/controlButtonState';
import {
  mapState,
  currentUserState,
  userNameState,
  deviceInfoState,
  isAdminState,
  traceMapState,
  allSubstancesDataState,
  isNewRecordCreatedState,
  imageFilenameState,
  isInspectionModalOpenState,
  isOperatorModalOpenState,
  mapReprocessTriggerState,
  wifiCharacteristicState,
  bluetoothServiceState
} from './stateManagement/commonState';
import {
  scanningState,
  scanHeightState,
  scanWidthState,
  pointsPerSideState,
  numLevelsState,
  numPointsState,
  singlePointMultiplierState,
  currentPathState,
  calibrationInfoState,
  defaultCalibrationInfo,
  scanTypeState
} from './stateManagement/processState';
import {
  Row,
  Col,
  message,
  Modal,
  Layout,
  Radio,
  notification,
  Progress,
  Button
} from 'antd';
import { substanceArray, substanceMap, substanceThresholds } from './utils/substancesAndColors';
import {
  connectButtonHandler,
  subscribeToCharacteristic,
  disconnectBluetoothDevice,
  writeToDevice,
  readEEPROM
} from './utils/bluetoothLogic';
import {
  calibrateRamanIntensity,
  removeBadPixels,
  interpolate,
  chunkArrayIntoN,
  arrayBufferToBufferCycle
} from './utils/helpers';
import {
  uploadSpectra,
  fetchSpectrum,
  fetchArchivedSpectra,
  fetchSubstances,
  fetchDeviceInfo,
  updateDeviceFirmwareVersionInDB,
  updateDeviceNetworkStatusInDB,
  updateDeviceCameraStatusInDB,
  uploadResults
} from './utils/dbHelpers';
import { calculateCenterZ } from './utils/calibrationHelpers';
import { getExpandingSquareSearchPoints } from './utils/getExpandingSquareSearch';
import logoImage from './images/ScatrLogo.svg';
import { parseEEPROM } from './utils/parseEEPROM';
import { getWavenumbers } from './utils/wavenumbers';
import ActionButtons from './components/ActionButtons';
import WifiConfigModal from './components/WifiConfigModal';
import { smooth } from './utils/modifiedSincSmoother.js'
import { AdditionalSettings } from './components/AdditionalSettings';
import { model1State, model2State } from './stateManagement/mlState';
import MassSpectrometryContainer from './components/MassSpectrometry/MassSpectrometryContainer';
import SubstanceInfoModal from './components/SubstanceInfoModal';
import SubstanceManagement from './components/SubstanceManagement';
import InspectionModal from './components/InspectionModal';
import RiskAwareness from './components/RiskAwareness';
import OperatorModal from './components/OperatorModal';
import { computeResults } from './utils/results.js';
import BluetoothImage from './components/BluetoothImage.js';

import {
  loadModels,
  getReconstructionError,
  getMLPredictionArray,
  getFentanylPrediction,
  getXylazinePrediction,
  getBenzodiazepinePrediction,
  getHydromorphonePrediction
} from './predict';

import { ASPLS } from './utils/baseline_aspls.js';
import { classifySpectrum, detectTraceFentanyl, detectTraceHydromorphone, detectTraceBenzodiazepine, detectTraceXylazine } from './utils/detect.js';

require('nerdamer/Solve');

const scanSettings = { 'quick': 7, 'standard': 13, 'detailed': 18 };

const standardWavenumbers = [...Array(2324 - 225 + 1)].map((_, i) => 225 + i);

const { Footer, Content } = Layout;

const key = 'updatable';

const tf = require('@tensorflow/tfjs');

let device;
let characteristic;

let bluetoothStream = [];

let worker;
let tensorflowWorker = [];
let numTensorflowWorkers = 1;

function App({ signOut }) {
  const [isReady, setIsReady] = useState(false);
  const [tensorflowWorkersInitialized, setTensorflowWorkersInitialized] = useState(false);
  const [clientUploadsWorkerInitialized, setClientUploadsWorkerInitialized] = useState(false);
  const numTensorflowWorkersInitialized = useRef(0);
  const [currentUser, setCurrentUser] = useAtom(currentUserState);
  const [username] = useAtom(userNameState);
  const [isAdmin, setIsAdmin] = useAtom(isAdminState);
  const [isInspectionModalOpen] = useAtom(isInspectionModalOpenState);
  const [, setIsOperatorModalOpen] = useAtom(isOperatorModalOpenState);

  const [settings, setSettings] = useState(false);
  const [showDeviceInfo, setShowDeviceInfo] = useState(false);
  const [map, setMap] = useAtom(mapState);
  const mapRef = useRef([]);
  const [traceMap, setTraceMap] = useAtom(traceMapState);
  const traceMapRef = useRef([]);
  const footerRef = React.useRef(null);

  const [scanType, setScanType] = useAtom(scanTypeState);
  const [scanWidth, setScanWidth] = useAtom(scanWidthState);
  const [scanHeight, setScanHeight] = useAtom(scanHeightState);
  const [pointsPerSide, setPointsPerSide] = useAtom(pointsPerSideState);
  const [numLevels, setNumLevels] = useAtom(numLevelsState);
  const [numPoints, setNumPoints] = useAtom(numPointsState);
  const [singlePointMultiplier, setSinglePointMultiplier] = useAtom(singlePointMultiplierState);

  const [minLaserPower] = useAtom(minLaserPowerState);
  const [maxLaserPower] = useAtom(maxLaserPowerState);
  const [minIntegrationTime] = useAtom(minIntegrationTimeState);
  const [maxIntegrationTime] = useAtom(maxIntegrationTimeState);
  const [center, setCenter] = useAtom(centerState);

  const [shouldReprocessTraceMap, setShouldReprocessTraceMap] = useState(false);

  const [scanning, setScanning] = useAtom(scanningState);
  const scanningRef = React.useRef(false);
  const [scanPhase, setScanPhase] = useState(null);
  const [calibrationInfo, setCalibrationInfo] = useAtom(calibrationInfoState);
  const [isNewRecordCreated, setIsNewRecordCreated] = useAtom(isNewRecordCreatedState);
  const [imageFilename] = useAtom(imageFilenameState);

  const [currentDataPoint, setCurrentDataPoint] = useState({});
  const [currentSerial, setCurrentSerial] = useState(null);
  const [currentMapID, setCurrentMapID] = useState(null);
  const currentMapIDRef = useRef(null);
  const [currentPath] = useAtom(currentPathState);

  const [deviceInfo, setDeviceInfo] = useAtom(deviceInfoState);
  const connectedDeviceInfoRef = useRef(null);
  const deviceInfoRef = useRef(null);
  const [deviceWavenumbers, setDeviceWavenumbers] = useState([]);
  const deviceWavenumbersRef = React.useRef([]);

  const workerMessageQueueRef = useRef([]);
  const workerMessageProcessingRef = useRef(false);
  const tensorflowWorkerMessageQueue = useRef([]);

  const warmupDelayTimeoutID = useRef(null);

  const [, setModel_1] = useAtom(model1State);
  const [, setModel_2] = useAtom(model2State);

  const consecutiveSaturatedCountRef = useRef(0);

  const [wifiConfigModalOpen, setWifiConfigModalOpen] = useState(false);
  const [bluetoothState, setBluetoothState] = useState('Disconnected');
  const [, setAllSubstancesData] = useAtom(allSubstancesDataState);

  const [mapReprocessTrigger, setMapReprocessTrigger] = useAtom(mapReprocessTriggerState);
  const [notificationAPI, notificationContextHolder] = notification.useNotification();
  const [mapReprocessProgress, setMapReprocessProgress] = useState(0);

  const [, setWifiCharacteristic] = useAtom(wifiCharacteristicState);
  const [, setBluetoothService] = useAtom(bluetoothServiceState);

  const [calibrationProgress, setCalibrationProgress] = useState(0);

  useEffect(() => {
    const initialLoad = async () => {
      let user = await Auth.currentAuthenticatedUser();
      setCurrentUser(user);
      worker = new Worker(new URL('./worker.js', import.meta.url));
      worker.onmessage = e => workerMessageHandler(e);
      await loadModels();
      const loadedModel1 = await tf.loadGraphModel('/tf/ml/model_1/model.json');
      const loadedModel2 = await tf.loadGraphModel('/tf/ml/model_2/model.json');
      setModel_1(loadedModel1);
      setModel_2(loadedModel2);
      for (let i = 0; i < numTensorflowWorkers; i++) {
        tensorflowWorker[i] = new Worker(new URL('./tensorflowWorker.js', import.meta.url));
        tensorflowWorker[i].onmessage = e => tensorflowWorkerMessageHandler(e);
        tensorflowWorker[i].postMessage({ type: 'INIT_MODELS' });
      }

    };
    initialLoad();
  }, []);

  useEffect(() => {
    if (tensorflowWorkersInitialized && clientUploadsWorkerInitialized) {
      setIsReady(true);
    }
  }, [tensorflowWorkersInitialized, clientUploadsWorkerInitialized]);

  useEffect(() => {
    if (bluetoothState === 'Disconnected') {
      setScanning(false);
    }
  }, [bluetoothState]);

  useEffect(() => { currentMapIDRef.current = currentMapID; }, [currentMapID]);
  useEffect(() => { traceMapRef.current = traceMap; }, [traceMap]);

  useEffect(() => {
    if (mapReprocessProgress > 0) {
      notificationAPI.open({
        key: 'reprocess',
        message: `Processing map...`,
        description: (<Progress percent={mapReprocessProgress} size="small" />),
        duration: 3,
        closeIcon: null
      });
    }
  }, [mapReprocessProgress]);

  useEffect(() => {
    if (mapReprocessTrigger) {

      const reprocessMap = async () => {
        setShouldReprocessTraceMap(true);
        let newMap = cloneDeep(map);
        for (const [i, dataPoint] of newMap.entries()) {
          setMapReprocessProgress(Math.round((i + 1) / newMap.length * 100));
          dataPoint["badPixelCorrectedSpectrum"] = removeBadPixels(dataPoint.rawSpectrum, deviceInfoRef.current.bad_pixels);
          dataPoint["smoothedSpectrum"] = smooth(dataPoint.badPixelCorrectedSpectrum, null);
          const baseline = ASPLS({ data: dataPoint["smoothedSpectrum"], x_data: deviceWavenumbersRef.current });
          dataPoint["baselineCorrectedSpectrum"] = dataPoint["smoothedSpectrum"].map((e, i) => Math.max(0, e - baseline[i]));
          dataPoint["intensityCorrectedSpectrum"] = calibrateRamanIntensity(dataPoint.baselineCorrectedSpectrum, deviceInfoRef.current.intensity_calibration);
          dataPoint["interpolatedSpectrum"] = [...dataPoint["processedSpectrum"]];
          dataPoint["processedSpectrum"] = interpolate(standardWavenumbers, deviceWavenumbersRef.current, dataPoint["intensityCorrectedSpectrum"]);
          let q = Math.hypot(...dataPoint["interpolatedSpectrum"]);
          dataPoint["normalizedSpectrum"] = dataPoint["interpolatedSpectrum"].map((e, i) => e / q);
          dataPoint.result = (await getMLPredictionArray([dataPoint.processedSpectrum]))[0];
          dataPoint.reconstructionError = (await getReconstructionError([dataPoint.processedSpectrum]))[0];
          dataPoint.fentanylPrediction = (await getFentanylPrediction([dataPoint.processedSpectrum]))[0][0];
          dataPoint.xylazinePrediction = (await getXylazinePrediction([dataPoint.processedSpectrum]))[0][0];
          dataPoint.benzodiazepinePrediction = (await getBenzodiazepinePrediction([dataPoint.processedSpectrum]))[0][0];
          dataPoint.hydromorphonePrediction = (await getHydromorphonePrediction([dataPoint.processedSpectrum]))[0][0];
          dataPoint.label = classifySpectrum(dataPoint.processedSpectrum, dataPoint.result, dataPoint.reconstructionError, dataPoint.rawSpectrum, dataPoint.benzodiazepinePrediction);
          dataPoint.mlOutput = dataPoint.result[0].map((f, i) => [substanceArray[i], f, dataPoint.result[1][i], dataPoint.result[2][i]]).sort((a, b) => b[1] - a[1]).map(g => [g[0], Math.round(g[1] * 1000) / 1000, Math.round(g[2] * 1000) / 1000, Math.round(g[3] * 1000) / 1000]);
        }
        setMap(newMap);
      }
      reprocessMap();
      setMapReprocessTrigger(false);
    }
  }, [mapReprocessTrigger]);

  useEffect(() => {
    setIsAdmin(currentUser?.attributes['custom:Role'] === '1');
    fetchSubstances().then((substancesFromDB) => {
      const allSubstancesData = {};
      substancesFromDB.forEach(s => {
        allSubstancesData[s.label] = { info: s.info };
      });
      setAllSubstancesData(allSubstancesData);
    });
  }, [currentUser]);

  useEffect(() => {
    setNumPoints(pointsPerSide === 1 && numLevels === 1 ? singlePointMultiplier : Math.pow(pointsPerSide, 2) * numLevels);
  }, [pointsPerSide, numLevels, singlePointMultiplier]);

  const ejectSample = async () => {
    if (bluetoothState === 'Connected') {
      await writeToDevice(characteristic, [
        ['stopScan'],
        ['laserOFF'],
        ['clearCoordiantes'],
        ['move', [12.5e6, 20e6, 15e6]],
        ['move', [12.5e6, 50e6, 15e6]],
        ['executeCommands']
      ]);
    }
  }

  useEffect(() => {
    scanningRef.current = scanning;
    if (!scanning && map.length > 0) {
      setScanPhase('Finished');
      ejectSample();
      saveAllScanData();
      setCalibrationInfo(defaultCalibrationInfo);
    } else if (!scanning && map.length === 0) {
      clearTimeout(warmupDelayTimeoutID.current);
      ejectSample();
      setCalibrationInfo(defaultCalibrationInfo);
      setScanWidth(5);
      setPointsPerSide(scanSettings[scanType]);
      setCenter({ x: deviceInfo?.center_calibration.x || defaultCenter.x, y: deviceInfo?.center_calibration.y || defaultCenter.y, z: deviceInfo?.center_calibration.z || defaultCenter.z });
    }
  }, [scanning]);

  useEffect(() => {
    if (isNewRecordCreated) {
      scanButtonHandler();
      setIsNewRecordCreated(false);
    }
  }, [isNewRecordCreated]);

  useEffect(() => {
    mapRef.current = map;
    if (!calibrationInfo.calibrating) {
      if (numPoints === map.length) {
        setScanning(false);
      }
      updateTraceResults(map);
    }
  }, [map]);

  useEffect(() => {
    deviceInfoRef.current = deviceInfo;
    if (deviceInfo) {
      setDeviceWavenumbers(
        getWavenumbers(500, deviceInfo.wavelength_calibration, deviceInfo.excitation_wavelength)
      );
    };
  }, [deviceInfo]);

  useEffect(() => {
    deviceWavenumbersRef.current = deviceWavenumbers;
  }, [deviceWavenumbers]);

  const mapViewButtonHandler = async (e, fromArchive = false) => {
    clear();
    let mapFromDB = fromArchive ? await fetchArchivedSpectra(e.map_id) : await fetchSpectrum(e.map_id);
    if (!mapFromDB?.length > 0) {
      message.error({ content: 'No spectra found in database.', key, duration: 10 });
      return;
    }
    setCurrentMapID(e.map_id);
    let storedDeviceInfo = await fetchDeviceInfo(e.device_id);
    setDeviceInfo(storedDeviceInfo);
    setCurrentSerial(e.serial);
    let uniqueX = new Set(mapFromDB.map(e => e.coordinate[0]));
    let uniqueY = new Set(mapFromDB.map(e => e.coordinate[1]));
    let uniqueZ = new Set(mapFromDB.map(e => e.coordinate[2]));
    setCenter({
      x: (Math.max(...uniqueX) + Math.min(...uniqueX)) / 2,
      y: (Math.max(...uniqueY) + Math.min(...uniqueY)) / 2,
      z: (Math.max(...uniqueZ) + Math.min(...uniqueZ)) / 2,
    });
    let numPointsX = [...uniqueX].length;
    let numPointsY = [...uniqueY].length;
    setPointsPerSide(Math.max(numPointsX, numPointsY));
    setNumLevels([...new Set(mapFromDB.filter((e, i) => mapFromDB[0].coordinate[0] === e.coordinate[0] && mapFromDB[0].coordinate[1] === e.coordinate[1]).map(e => e.coordinate[2]))].length);
    let newScanWidth;
    if (numPointsX >= numPointsY) {
      newScanWidth = Math.max(...uniqueX) - Math.min(...uniqueX);
    } else {
      newScanWidth = Math.max(...uniqueY) - Math.min(...uniqueY);
    }
    setScanWidth(newScanWidth)
    setScanHeight(Math.max(...uniqueZ) - Math.min(...uniqueZ));
    if (newScanWidth === 0) { setSinglePointMultiplier(mapFromDB.length) }
    mapFromDB = mapFromDB.map(e => {
      let q = Math.hypot(...e.processed_spectrum);
      return { ...e, normalizedSpectrum: e.processed_spectrum.map(e => e / q) }
    });
    let chuckedMapDB = chunkArrayIntoN(mapFromDB, numTensorflowWorkers);
    for (let i = 0; i < numTensorflowWorkers; i++) {
      tensorflowWorker[i].postMessage({ type: 'PREDICT', data: chuckedMapDB[i] });
    }
  }

  const clear = async () => {
    return new Promise(async (resolve, reject) => {
      bluetoothStream = [];
      setMap([]);
      setTraceMap([]);
      setCurrentSerial(null);
      setCurrentMapID(null);
      consecutiveSaturatedCountRef.current = 0;
      if (connectedDeviceInfoRef?.current?.center_calibration) {
        setCenter(prevCenter => {
          let newCenter = { ...prevCenter };
          newCenter.x = connectedDeviceInfoRef.current.center_calibration.x;
          newCenter.y = connectedDeviceInfoRef.current.center_calibration.y;
          return newCenter
        });
      } else {
        setCenter({ x: defaultCenter.x, y: defaultCenter.y, z: defaultCenter.z });
      }
      setDeviceInfo(connectedDeviceInfoRef.current);
      setScanPhase(null);
      resolve();
    });
  }

  const postScanHandler = async dataPoint => {
    dataPoint["badPixelCorrectedSpectrum"] = removeBadPixels(dataPoint.rawSpectrum, deviceInfoRef.current.bad_pixels);
    dataPoint["smoothedSpectrum"] = smooth(dataPoint.badPixelCorrectedSpectrum, null);
    worker.postMessage(dataPoint);
  }

  function dequeueWorkerMessage() {
    if (workerMessageQueueRef.current.length > 0) {
      const dequeuedMessage = workerMessageQueueRef.current[0];
      workerMessageQueueRef.current = workerMessageQueueRef.current.slice(1);
      return dequeuedMessage;
    }
    return null;
  }

  async function processWorkerMessages() {
    if (workerMessageProcessingRef.current) return;
    workerMessageProcessingRef.current = true;

    while (workerMessageQueueRef.current.length > 0) {
      const dataPoint = dequeueWorkerMessage();
      dataPoint["intensityCorrectedSpectrum"] = calibrateRamanIntensity(dataPoint.baselineCorrectedSpectrum, deviceInfoRef.current.intensity_calibration);
      dataPoint["interpolatedSpectrum"] = interpolate(standardWavenumbers, deviceWavenumbersRef.current, dataPoint["intensityCorrectedSpectrum"]);
      dataPoint["processedSpectrum"] = dataPoint["interpolatedSpectrum"];
      let q = Math.hypot(...dataPoint["interpolatedSpectrum"]);
      dataPoint["normalizedSpectrum"] = dataPoint["interpolatedSpectrum"].map((e, i) => e / q);
      dataPoint.result = (await getMLPredictionArray([dataPoint.processedSpectrum]))[0];
      dataPoint.reconstructionError = (await getReconstructionError([dataPoint.processedSpectrum]))[0];
      dataPoint.fentanylPrediction = (await getFentanylPrediction([dataPoint.processedSpectrum]))[0][0];
      dataPoint.xylazinePrediction = (await getXylazinePrediction([dataPoint.processedSpectrum]))[0][0];
      dataPoint.benzodiazepinePrediction = (await getBenzodiazepinePrediction([dataPoint.processedSpectrum]))[0][0];
      dataPoint.hydromorphonePrediction = (await getHydromorphonePrediction([dataPoint.processedSpectrum]))[0][0];
      dataPoint.label = classifySpectrum(dataPoint.processedSpectrum, dataPoint.result, dataPoint.reconstructionError, dataPoint.rawSpectrum, dataPoint.benzodiazepinePrediction);
      dataPoint.mlOutput = dataPoint.result[0].map((f, i) => [substanceArray[i], f, dataPoint.result[1][i], dataPoint.result[2][i]]).sort((a, b) => b[1] - a[1]).map(g => [g[0], Math.round(g[1] * 1000) / 1000, Math.round(g[2] * 1000) / 1000, Math.round(g[3] * 1000) / 1000])
      setCurrentDataPoint(dataPoint);
      await new Promise((resolve) => setTimeout(resolve, 20)); // Allow for user interaction?
    }

    workerMessageProcessingRef.current = false;
  }

  const processtensorflowWorkerMessages = async () => {
    if (tensorflowWorkerMessageQueue.current.length === numTensorflowWorkers) {
      let sortedFragments = tensorflowWorkerMessageQueue.current.filter(arr => arr.length > 0).sort((a, b) => a[0].index - b[0].index);
      let mapFromDB = sortedFragments.flat();
      mapFromDB = mapFromDB.map(e => {
        const label = classifySpectrum(e.processed_spectrum, e.result, e.reconstructionError, e.raw_spectrum, e.benzodiazepinePrediction)
        if (e.result[0][substanceMap.get(label)] < substanceThresholds[label]) {
          e.result[0][substanceMap.get(label)] = substanceThresholds[label] + 0.001;
        }
        return { ...e, label: classifySpectrum(e.processed_spectrum, e.result, e.reconstructionError, e.raw_spectrum, e.benzodiazepinePrediction) }
      });
      mapFromDB = mapFromDB.map(e => { return { ...e, mlOutput: e.result[0].map((f, i) => [substanceArray[i], f, e.result[1][i], e.result[2][i]]).sort((a, b) => b[1] - a[1]).map(g => [g[0], Math.round(g[1] * 1000) / 1000, Math.round(g[2] * 1000) / 1000, Math.round(g[3] * 1000) / 1000]) } });
      mapFromDB = mapFromDB.map((e) => {
        return ({
          index: e.index,
          x: e.coordinate[0],
          y: e.coordinate[1],
          z: e.coordinate[2],
          power: e.laser_power,
          integrationTime: e.integration_time,
          rawSpectrum: e.raw_spectrum,
          processedSpectrum: e.processed_spectrum,
          normalizedSpectrum: e.normalizedSpectrum,
          result: e.result,
          label: e.label,
          mlOutput: e.mlOutput,
          reconstructionError: e.reconstructionError,
          fentanylPrediction: e.fentanylPrediction,
          xylazinePrediction: e.xylazinePrediction,
          benzodiazepinePrediction: e.benzodiazepinePrediction,
          hydromorphonePrediction: e.hydromorphonePrediction,
        })
      });
      setMap(mapFromDB);
      setScanPhase('Finished');
      message.success({ content: 'Loaded!', key, duration: 3 });
      tensorflowWorkerMessageQueue.current = [];
    }
  }

  const workerMessageHandler = e => {
    workerMessageQueueRef.current.push(e.data);
    processWorkerMessages();
  }

  const tensorflowWorkerMessageHandler = e => {
    const { type, data } = e.data;
    switch (type) {
      case 'PREDICTION_RESULT':
        tensorflowWorkerMessageQueue.current.push(data);
        processtensorflowWorkerMessages();
        break;
      case 'MODEL_INITIALIZED':
        numTensorflowWorkersInitialized.current++;
        if (numTensorflowWorkersInitialized.current === numTensorflowWorkers) {
          setTensorflowWorkersInitialized(true);
        }
    }
  }

  useEffect(() => {
    if (scanning && !calibrationInfo.calibrating) {
      setMap(prevMap => {
        let newMap = cloneDeep(prevMap);
        currentDataPoint.index = prevMap.length;
        newMap.push(currentDataPoint);
        return [...prevMap, currentDataPoint];
      });
    }
    else if (scanning && calibrationInfo.calibrating) {
      setCalibrationInfo(prev => {
        let newCalibrationInfo = cloneDeep(prev);
        let dataPoint = cloneDeep(currentDataPoint);
        dataPoint.processedSpectrum = dataPoint.processedSpectrum.slice(0, 1576);
        if (Math.max(...dataPoint.processedSpectrum) >= 65535) { dataPoint.processedSpectrum.fill(0); }
        if (prev.firstXYCalibration.active) {
          newCalibrationInfo.firstXYCalibration.data.push(dataPoint);
        } else if (prev.firstHeightCalibration.active) {
          newCalibrationInfo.firstHeightCalibration.data.push(dataPoint);
        } else if (prev.secondXYCalibration.active) {
          newCalibrationInfo.secondXYCalibration.data.push(dataPoint);
        } else if (prev.secondHeightCalibration.active) {
          newCalibrationInfo.secondHeightCalibration.data.push(dataPoint);
        }
        return newCalibrationInfo;
      });
    }
  }, [currentDataPoint]);

  useEffect(() => {
    setCalibrationProgress(Math.round(((calibrationInfo.firstHeightCalibration.data.length) / calibrationInfo.firstHeightCalibration.settings.numLevels) * 100));
    if (calibrationInfo.firstHeightCalibration.data.length === (calibrationInfo.firstHeightCalibration.settings.numLevels) && calibrationInfo.firstHeightCalibration.active) {
      let newCalibrationInfo = cloneDeep(calibrationInfo);
      newCalibrationInfo.firstHeightCalibration.calculated.center_z = calculateCenterZ(newCalibrationInfo.firstHeightCalibration.data);
      let newCenter = cloneDeep(center);
      newCenter.z = newCalibrationInfo.firstHeightCalibration.calculated.center_z;
      setCenter(newCenter);

      newCalibrationInfo.firstHeightCalibration.active = false;
      newCalibrationInfo.calibrating = false;
      setCalibrationInfo(newCalibrationInfo);
      setScanPhase('Scanning');

      let points = getExpandingSquareSearchPoints(newCenter, scanWidth, scanHeight, pointsPerSide, numLevels, singlePointMultiplier);
      let path = points.map((point, i) => [Math.round(point[0] * 1e6), Math.round(point[1] * 1e6), Math.round(point[2] * 1e6)]);
      let commands = [];
      if (minLaserPower === maxLaserPower && minIntegrationTime === maxIntegrationTime) {
        commands.push(
          ['setIntegrationTime', maxIntegrationTime],
          ['setLaserPower', maxLaserPower],
          ['sendCoordinates', path],
          ['scanCoordinates']
        );
      } else {
        let laserPowers = Array.from({ length: currentPath.length }).map((e, i, _, step = (maxLaserPower - minLaserPower) / (currentPath.length - 1)) => Math.round(minLaserPower + i * step));
        let integrationTimes = Array.from({ length: currentPath.length }).map((e, i, _, step = (maxIntegrationTime - minIntegrationTime) / (currentPath.length - 1)) => Math.round(minIntegrationTime + i * step));
        for (let i = 0; i < path.length; i++) {
          commands.push(
            ['setIntegrationTime', integrationTimes[i]],
            ['setLaserPower', laserPowers[i]],
            ['sendCoordinates', path[i]],
            ['scanCoordinates']
          );
        }
      }
      commands.push(['executeCommands']);
      writeToDevice(characteristic, commands);
    }
  }, [calibrationInfo]);

  const handleCharacteristicValueChanged = async (event) => {
    if (!scanningRef.current) { return }
    let Uint16View = new Uint16Array(event.target.value.buffer);
    bluetoothStream.push(...Array.from(Uint16View));
    if (bluetoothStream.length >= 510) {
      let spectrumData = bluetoothStream.splice(0, 510);
      let rawSpectrum = spectrumData.slice(0, 500);
      let isSaturated = rawSpectrum.some(value => value >= 65535);
      if (isSaturated) {
        consecutiveSaturatedCountRef.current += 1;
        let numSaturatedPixels = rawSpectrum.filter(value => value >= 65535);
        if (consecutiveSaturatedCountRef.current === 1) {
          message.warning({ content: `Sensor saturation detected. ${numSaturatedPixels.length} pixel(s) affected.`, duration: 9 });
        } else if (consecutiveSaturatedCountRef.current === 2) {
          message.warning({ content: `Two consecutive sensor saturation events detected.`, duration: 9 });
        } else if (consecutiveSaturatedCountRef.current === 3) {
          setScanning(false);
          setScanPhase('Finished');
          message.error({ content: `Three consecutive sensor saturation events detected. Aborting scan.`, duration: 9 });
        }
      } else if (consecutiveSaturatedCountRef.current > 0 && !isSaturated) {
        consecutiveSaturatedCountRef.current = 0;
      }
      let metaData16 = spectrumData.slice(500, 510);
      let metaData16View = new Uint16Array(metaData16);
      let metaData32View = new Uint32Array(metaData16View.buffer);
      let metaData = Array.from(metaData32View);
      let dataPoint = {};
      dataPoint.x = metaData[0] / 1e6;
      dataPoint.y = metaData[1] / 1e6;
      dataPoint.z = metaData[2] / 1e6;
      dataPoint.power = metaData[3];
      dataPoint.integrationTime = metaData[4];
      dataPoint.rawSpectrum = rawSpectrum;
      if (dataPoint.power > 450 || dataPoint.x > 25 || dataPoint.y > 50 || dataPoint.z > 20) {
        setScanning(false);
        setScanPhase('Finished');
        message.error({ content: `Bluetooth transmission error detected. Please refresh the page.`, duration: 3600 });
        throw new Error('Bluetooth transmission error detected.');
      } else {
        postScanHandler(dataPoint);
      }
    }
  }

  const updateTraceResults = (newMap) => {
    if (newMap.length === 0) {
      setTraceMap([]);
      return;
    }
    setTraceMap(draft => {
      if (shouldReprocessTraceMap) {
        draft.splice(0, draft.length);
      }
      let startIndex = shouldReprocessTraceMap ? 0 : draft.length;
      for (let i = startIndex; i < newMap.length; i++) {
        let z = newMap[i].z;
        const { label, processedSpectrum, result, mlOutput, fentanylPrediction, xylazinePrediction, benzodiazepinePrediction, hydromorphonePrediction } = newMap[i];

        let { hasTraceFentanyl, traceFentanylLabel } = detectTraceFentanyl(label, processedSpectrum, result, fentanylPrediction, mlOutput);
        let { hasTraceXylazine, traceXylazineLabel } = detectTraceXylazine(label, processedSpectrum, result, mlOutput, xylazinePrediction);
        let hasTraceHydromorphone = detectTraceHydromorphone(label, processedSpectrum, result, hydromorphonePrediction);
        let { hasTraceBenzodiazepine, traceBenzodiazepineLabel } = detectTraceBenzodiazepine(label, processedSpectrum, result, benzodiazepinePrediction, mlOutput);

        if (hasTraceFentanyl) { z += 0.1; draft.push({ ...newMap[i], z: z, label: traceFentanylLabel }); }
        if (hasTraceHydromorphone) { z += 0.1; draft.push({ ...newMap[i], z: z, label: 'Hydromorphone' }); }
        if (hasTraceBenzodiazepine) { z += 0.1; draft.push({ ...newMap[i], z: z, label: traceBenzodiazepineLabel }); }
        if (hasTraceXylazine) { z += 0.1; draft.push({ ...newMap[i], z: z, label: traceXylazineLabel }); }
        if (!hasTraceFentanyl && !hasTraceHydromorphone && !hasTraceBenzodiazepine && !hasTraceXylazine) { draft.push({}); }
      }
    });

    if (shouldReprocessTraceMap) {
      setShouldReprocessTraceMap(false);
    }
  }

  const connectButtonCallback = async () => {
    try {
      if (bluetoothState === 'Connected') {
        await disconnectBluetoothDevice(device);
        setBluetoothState('Disconnected');
      } else {
        const [connectedDevice, bluetoothCharacteristic, wifiCharacteristic, bluetoothService] = await connectButtonHandler(onBluetoothDisconnect, message, key);
        device = connectedDevice;
        characteristic = bluetoothCharacteristic;
        setWifiCharacteristic(wifiCharacteristic);
        setBluetoothService(bluetoothService);
        let status = await subscribeToCharacteristic(characteristic, handleCharacteristicValueChanged);
        setBluetoothState(status);
        if (status === 'Connected') {
          message.success({ content: 'Connected to Series One', key, duration: 3 });
          let eepromData = await readEEPROM(characteristic);
          let eepromDataBuffer = arrayBufferToBufferCycle(eepromData.buffer);
          let deviceInfo = { ...parseEEPROM(eepromDataBuffer) }
          if (deviceInfo.device_firmware_version) {
            await updateDeviceFirmwareVersionInDB(deviceInfo.device_id, deviceInfo.device_firmware_version);
          }
          await updateDeviceNetworkStatusInDB(deviceInfo?.device_id, deviceInfo?.device_network_status);
          await updateDeviceCameraStatusInDB(deviceInfo?.device_id, deviceInfo?.device_camera_status);
          let storedDeviceInfo = await fetchDeviceInfo(deviceInfo.device_id);
          if (storedDeviceInfo.center_calibration) {
            deviceInfo.center_calibration = storedDeviceInfo.center_calibration;
            setCenter(prevCenter => {
              let newCenter = { ...prevCenter };
              newCenter.x = storedDeviceInfo.center_calibration.x;
              newCenter.y = storedDeviceInfo.center_calibration.y;
              return newCenter
            });
          }
          connectedDeviceInfoRef.current = deviceInfo;
          setDeviceInfo(deviceInfo);
          await writeToDevice(characteristic, [
            ['setStageAccelerationAndSpeedXY', [120, 18e6]],
            ['setStageAccelerationAndSpeedZ', [120, 42e6]],
            ['executeCommands']
          ]);
          return true
        } else {
          message.warning({ content: 'Failed to connect.', key, duration: 3 });
          return false;
        }
      }
    } catch (error) {
      message.warning({ content: 'Failed to connect.', key, duration: 3 });
    }
  }

  const onBluetoothDisconnect = () => {
    setBluetoothState('Disconnected');
    connectedDeviceInfoRef.current = null;
    setDeviceInfo(null);
  }
  const settingsToggleHandler = _ => { setSettings(!settings) };
  const deviceInfoToggleHandler = async () => {
    if (characteristic) {
      setShowDeviceInfo(!showDeviceInfo)
    } else {
      try {
        await connectButtonCallback();
        if (bluetoothState === 'Connected') {
          setShowDeviceInfo(!showDeviceInfo);
        } else {
          message.warning({ content: 'Failed to connect.', key, duration: 3 });
        }
      } catch (reason) {
        console.error(reason);
      }
    }
  };

  const scanButtonHandler = async () => {
    setScanPhase('Initializing');
    let path = currentPath.map((point, i) => [Math.round(point[0] * 1e6), Math.round(point[1] * 1e6), Math.round(point[2] * 1e6)]);
    let initCommands = [];
    let laserWarmupTime = 15000;
    if (deviceInfo.device_camera_status && deviceInfo.device_network_status) {
      laserWarmupTime = 28000;
      initCommands.push(
        ['move', [12.5e6, 45e6, 0e6]],
        ['captureImage', [imageFilename]],
      );
    }
    initCommands.push(
      ['move', [12.5e6, 24e6, 15e6]],
      ['setLaserPower', 450],
      ['laserON'],
      ['executeCommands']
    );
    await writeToDevice(characteristic, initCommands);
    warmupDelayTimeoutID.current = setTimeout(async () => {
      let commands = [];
      if (!calibrationInfo.calibrate) {
        setScanPhase('Scanning');
        if (minLaserPower === maxLaserPower && minIntegrationTime === maxIntegrationTime) {
          commands.push(
            ['setIntegrationTime', maxIntegrationTime],
            ['setLaserPower', maxLaserPower],
            ['sendCoordinates', path],
            ['scanCoordinates']
          );
        } else {
          let laserPowers = Array.from({ length: currentPath.length }).map((e, i, _, step = (maxLaserPower - minLaserPower) / (currentPath.length - 1)) => Math.round(minLaserPower + i * step));
          let integrationTimes = Array.from({ length: currentPath.length }).map((e, i, _, step = (maxIntegrationTime - minIntegrationTime) / (currentPath.length - 1)) => Math.round(minIntegrationTime + i * step));
          for (let i = 0; i < path.length; i++) {
            commands.push(
              ['setIntegrationTime', integrationTimes[i]],
              ['setLaserPower', laserPowers[i]],
              ['sendCoordinates', path[i]],
              ['scanCoordinates']
            );
          }
        }
        commands.push(['executeCommands']);
        await writeToDevice(characteristic, commands);
      } else {
        setScanPhase('Calibrating');
        setCalibrationInfo(prevCalibrationInfo => {
          let newCalibrationInfo = cloneDeep(prevCalibrationInfo);
          newCalibrationInfo.firstHeightCalibration.active = true;
          return newCalibrationInfo;
        });
        let params = calibrationInfo.firstHeightCalibration.settings;
        let heightCalibrationCenter = { ...deviceInfoRef.current?.center_calibration, z: params.center_z };
        let points = getExpandingSquareSearchPoints(heightCalibrationCenter, 5, params.scanHeight, params.pointsPerSide, params.numLevels, 1);
        let path = points.map((point, i) => [Math.round(point[0] * 1e6), Math.round(point[1] * 1e6), Math.round(point[2] * 1e6)]);
        await writeToDevice(characteristic, [['setLaserPower', 450], ['setIntegrationTime', 500], ['sendCoordinates', path], ['scanCoordinates'], ['executeCommands']]);
      }
    }, laserWarmupTime);
  }

  const stepStartScan = _ => {
    setScanning(true);
    if (calibrationInfo.calibrate) {
      setCalibrationInfo(prevCalibrationInfo => {
        let newCalibrationInfo = cloneDeep(prevCalibrationInfo);
        newCalibrationInfo.calibrating = true;
        return newCalibrationInfo;
      });
    }
  }

  const saveSpectra = async ({ map, deviceID, mapID }) => {
    const res = await uploadSpectra({
      indices: map.map(e => e.index),
      coordinates: map.map(e => [e.x, e.y, e.z]),
      laserPowers: map.map(e => e.power),
      integrationTimes: map.map(e => e.integrationTime),
      rawSpectra: map.map(e => e.rawSpectrum),
      processedSpectra: map.map(e => e.processedSpectrum),
      labels: map.map(e => e.label),
      username,
      device_id: deviceID,
      map_id: mapID
    });
    return res.status === 'success';
  }

  const saveResults = async ({ mapID }) => {
    const results = computeResults(mapRef.current, traceMapRef.current);
    const res = await uploadResults({ mapID, results });
    return res.status === 'success' || res.status === 'info';
  }

  const saveAllScanData = async _ => {
    message.loading({ content: 'Saving...', key, duration: 0 });
    const spectraSaved = await saveSpectra({ map: mapRef.current, deviceID: deviceInfoRef.current.device_id, mapID: currentMapIDRef.current });
    const resultsSaved = await saveResults({ mapID: currentMapIDRef.current });
    if (spectraSaved && resultsSaved) {
      message.success({ content: 'Saved!', key, duration: 5 });
    } else {
      message.error({ content: 'Failed to save.', key, duration: 5 });
    }
  }

  const mainButtonHandler = async () => {
    if (scanPhase === 'Finished') {
      clear();
      setScanWidth(5);
      setPointsPerSide(scanSettings[scanType]);
      setCenter({ x: deviceInfo.center_calibration.x, y: deviceInfo.center_calibration.y, z: defaultCenter.z });
      setScanPhase(null);
    } else if (bluetoothState === 'Disconnected') {
      const connectedToDevice = await connectButtonCallback();
      if (connectedToDevice) {
        writeToDevice(characteristic, [
          ['laserOFF'],
          ['move', [12.5e6, 50e6, 20e6]],
          ['executeCommands']
        ]);
      }
    } else if (scanning) {
      setScanning(false);
      setScanPhase('Finished');
    } else {
      setIsOperatorModalOpen(true);
    }
  }

  return (
    <Layout style={{ maxWidth: '1000px', margin: '0 auto', position: 'relative', background: 'white' }}>
      {
        !isReady ?
          <>
            <img src={logoImage} height={"36px"} alt="" style={{ position: 'absolute', top: '30px', left: '6%' }} />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
              <div style={{ width: '150px', margin: '0 auto', textAlign: 'center' }}>
                <Lottie animationData={loadingAnimation} loop={true} />
                <div>Loading...</div>
              </div>
            </div>
          </> : null
      }
      <>
        <Content style={{
          opacity: isReady ? 1 : 0,
          visibility: isReady ? 'visible' : 'hidden',
          transition: 'opacity 1s ease, visibility 1s ease',
        }}>
          <img src={logoImage} height={"36px"} alt="" style={{ position: 'absolute', top: '30px', left: '6%' }} />
          {notificationContextHolder}
          {isInspectionModalOpen ? <InspectionModal map={map} /> : null}
          <WifiConfigModal
            isModalOpen={wifiConfigModalOpen}
            setWifiConfigModalOpen={setWifiConfigModalOpen}
            bluetoothState={bluetoothState}
            characteristic={characteristic}
          />
          <OperatorModal stepStartScan={stepStartScan} />
          <Modal
            open={showDeviceInfo}
            title='Device Info'
            footer={[]}
            closable={true}
            onCancel={deviceInfoToggleHandler}
            width='80%'
          >
            <DeviceInfoTable />
          </Modal>
          <SubstanceInfoModal />
          <ActionButtons
            signOut={signOut}
            settingsToggleHandler={settingsToggleHandler}
            deviceInfoToggleHandler={deviceInfoToggleHandler}
            setWifiConfigModalOpen={setWifiConfigModalOpen}
            wifiConfigModalOpen={wifiConfigModalOpen}
            bluetoothState={bluetoothState}
            currentSerial={currentSerial}
          />
          <div style={{ width: '100%', height: '90px' }}></div>
          {
            settings ?
              <AdditionalSettings /> : null
          }
          <Row justify="center">
            <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
              <Radio.Group value={scanType} disabled={map.length} >
                <Radio.Button value="quick" onChange={() => { setScanType('quick'); setPointsPerSide(scanSettings.quick); setScanWidth(5) }} style={{ width: 120, height: 'auto', textAlign: 'center', lineHeight: 1, padding: '8px 0' }}>⚡️&nbsp;&nbsp;Quick<br /><span style={{ fontSize: '9px' }}>3 min</span></Radio.Button>
                <Radio.Button value="standard" onChange={() => { setScanType('standard'); setPointsPerSide(scanSettings.standard); setScanWidth(5) }} style={{ width: 120, height: 'auto', textAlign: 'center', lineHeight: 1, padding: '8px 0' }}>⚖️&nbsp;&nbsp;Standard<br /><span style={{ fontSize: '9px' }}>7 min</span></Radio.Button>
                <Radio.Button value="detailed" onChange={() => { setScanType('detailed'); setPointsPerSide(scanSettings.detailed); setScanWidth(5) }} style={{ width: 120, height: 'auto', textAlign: 'center', lineHeight: 1, padding: '8px 0' }}>🔬&nbsp;&nbsp;Detailed<br /><span style={{ fontSize: '9px' }}>13 min</span></Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
          <div className={classes.mapContainer}>
            <PrimaryCharts currentSerial={currentSerial} currentMapID={currentMapID} />
          </div>
          <Row justify="center" align="middle" style={{ height: '100px', margin: '15px 0' }}>
            <Col span={24} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {
                (scanPhase === 'Initializing' || scanPhase === 'Calibrating' || scanPhase === 'Scanning') ?
                  <>
                    <p style={{ margin: 0 }}>{scanPhase}</p>
                    <div style={{ width: '100%', height: '47px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      {scanning && (scanPhase === 'Initializing') ?
                        <Lottie animationData={lineSwirlLoadAnimation} loop={true} style={{ height: '50px', marginTop: '-15px' }} />
                        : null
                      }
                      {scanPhase === 'Calibrating' ? <Progress style={{ width: '50%' }} percent={calibrationProgress} status='active' /> : null}
                      {scanPhase === 'Scanning' ? <Progress style={{ width: '50%' }} percent={Math.round((map.length / numPoints) * 100)} status='active' /> : null}
                    </div>
                  </>
                  : null
              }
              <Button type={!scanning ? 'primary' : undefined} danger={scanning} ghost onClick={() => mainButtonHandler({ bluetoothState, scanning, scanPhase })}>
                {
                  scanPhase === 'Finished' ? 'Reset'
                    : bluetoothState === 'Disconnected' ? 'Connect'
                      : !scanning ? 'Start Scan' : 'Stop'
                }
              </Button>
            </Col>
          </Row>
          {settings ? <SettingsButtons /> : null}
          <ClientUploads
            mapViewButtonHandler={mapViewButtonHandler}
            setCurrentSerial={setCurrentSerial}
            setCurrentMapID={setCurrentMapID}
            map={map}
            traceMap={traceMap}
            setClientUploadsWorkerInitialized={setClientUploadsWorkerInitialized}
          />
          <Row justify="center" align="middle">
            <Col span={21}>
              <RiskAwareness />
            </Col>
          </Row>
          <Row justify="center" align="middle">
            <Col span={21}>
              <MassSpectrometryContainer />
            </Col>
          </Row>
          {isAdmin && username === 'admin' ?
            <Row justify="center" align="middle">
              <Col span={21}>
                <SubstanceManagement />
              </Col>
            </Row> : null
          }
          <BluetoothImage />
        </Content >
        <Footer ref={footerRef} style={{ marginTop: '30px', textAlign: 'center', backgroundColor: 'white' }}>
          Copyright © {new Date().getFullYear()} Scatr Inc. All rights reserved. Jan 13, 2025 Version.
        </Footer>
      </>
    </Layout >
  );
}

export default App;