import { substanceArray, substanceMap, substanceThresholds } from './substancesAndColors';
import { indexOfMax } from '../utils/helpers';

const benzodiazepineLabels = ['Alprazolam', 'Bromazepam', 'Bromazolam', 'Clonazepam', 'Desalkylgidazepam', 'Diazepam', 'Etizolam', 'Flubromazepam', 'Flurazepam', 'Lorazepam', 'Oxazepam', 'Temazepam', 'Triazolam'];
const xylazineLabels = ['Xylazine', 'Xylazine hydrochloride'];
const fentanylLabels = ['Acetylfentanyl', 'Carfentanil', 'Despropionyl fentanyl', 'Fentanyl', 'Furanylfentanyl', 'Parafluorofentanyl', 'Remifentanil'];

const reconstructionErrorThreshold = 0.000145;
const signalThreshold = 750;

export const classifySpectrum = (processedSpectrum, predictionArray, reconstructionError, rawSpectrum, benzodiazepinePrediction) => {
    let classification = 'Unknown'
    let maxRawValue = Math.max(...rawSpectrum);
    if (maxRawValue >= 65535) { return 'Unknown' }
    let maxProcessedValue = Math.max(...processedSpectrum);
    if (maxProcessedValue < signalThreshold) {
        classification = 'No Signal';
    }
    else if (processedSpectrum.indexOf(maxProcessedValue) > 1576) {
        classification = 'No Signal';
    }
    else if (reconstructionError > reconstructionErrorThreshold) {
        classification = 'Unknown';
        let index = indexOfMax(predictionArray[0]);
        if (
            ['Caffeine', 'Dimethyl sulfone', 'Mannitol', 'Erythritol', 'Inositol', 'Sorbitol', 'Xylitol'].includes(substanceArray[index])
            && predictionArray[0][index] > 0.42
            && reconstructionError < 0.00024
        ) {
            classification = substanceArray[index];
        }
    }
    else {
        let index = indexOfMax(predictionArray[0]);
        classification = predictionArray[0][index] > substanceThresholds[substanceArray[index]] ? substanceArray[index] : 'Unknown';
    }

    if (benzodiazepineLabels.includes(classification)) {
        const topTraceBenzodiazepineResult = benzodiazepineLabels.map((label, i) => ({ label: label, prediction: benzodiazepinePrediction[i] })).sort((a, b) => b.prediction - a.prediction)[0]
        if (topTraceBenzodiazepineResult.prediction > 0.2) {
            classification = benzodiazepineLabels.map((label, i) => ({ label: label, prediction: benzodiazepinePrediction[i] })).sort((a, b) => b.prediction - a.prediction)[0].label;
        } else {
            classification = 'Unknown';
        }
    }

    return classification
}

export const detectTraceFentanyl = (label, processedSpectrum, result, fentanylMLOutput, mlOutput) => {
    const approvedLabels = ['Caffeine', 'Dimethyl sulfone', 'Mannitol', 'Erythritol', 'Inositol', 'Sorbitol', 'Xylitol'];
    if (!approvedLabels.includes(label)) { return { hasTraceFentanyl: false, traceFentanylLabel: null } };
    const { maxFEMLValue, maxFEMLIndex } = fentanylMLOutput.reduce((acc, value, index) => {
        if (value > acc.maxFEMLValue) {
            return { maxFEMLValue: value, maxFEMLIndex: index };
        }
        return acc;
    }, { maxFEMLValue: -Infinity, maxFEMLIndex: -1 });
    const fentanylLabel = fentanylLabels[maxFEMLIndex];
    const rankings = mlOutput.map(e => e[0]);
    const indexTopBase = rankings.findIndex(name => name.toLowerCase().includes('fent'));
    const maxMLValue = Math.max(mlOutput[indexTopBase][2], mlOutput[indexTopBase][3]);
    const maxInRegion = Math.max(...processedSpectrum.slice(1000 - 225 - 5, 1000 - 225 + 5 + 1));
    const isPeak = Math.max(...processedSpectrum.slice(1000 - 225 - 10, 1000 - 225 - 5)) < maxInRegion && Math.max(...processedSpectrum.slice(1000 - 225 + 6, 1000 - 225 + 11)) < maxInRegion;
    let threshold = 0.75;
    threshold = maxMLValue > 0.01 ? 0.035 : threshold;
    threshold = (isPeak && maxMLValue > 0.01) ? 0.015 : threshold;
    if (maxFEMLValue >= threshold && processedSpectrum[1000 - 225] > 140) {
        return { hasTraceFentanyl: true, traceFentanylLabel: 'Fentanyl' };
    }
    return { hasTraceFentanyl: false, traceFentanylLabel: null };
}

export const detectTraceHydromorphone = (label, processedSpectrum, result, hydromorphonePrediction) => {
    if (!['Lactose', 'alpha-Lactose'].includes(label)) { return false; }
    if (hydromorphonePrediction[1] > 0.55) { return true; } else { return false; }
}

export const detectTraceBenzodiazepine = (label, processedSpectrum, result, benzodiazepinePrediction, mlOutput) => {
    const approvedLabels = ['Caffeine', 'Dimethyl sulfone', 'Mannitol', 'Erythritol', 'Inositol', 'Sorbitol', 'Xylitol', 'Acetylfentanyl', 'Carfentanil', 'Despropionyl fentanyl', 'Fentanyl', 'Furanylfentanyl', 'Parafluorofentanyl', 'Remifentanil'];
    if (!approvedLabels.includes(label)) { return { hasTraceBenzodiazepine: false, traceBenzodiazepineLabel: null } };
    const benzodiazepinePredictionClassification = benzodiazepinePrediction;
    const passingBenzodiazepines = [];
    benzodiazepinePredictionClassification.forEach((e, i) => {
        let threshold = benzodiazepineLabels[i] === 'Bromazolam' ? 0.3 : 0.7;
        threshold = benzodiazepineLabels[i] === 'Oxazepam' ? 0.9 : threshold;
        if (e >= threshold && result[0][substanceMap.get(benzodiazepineLabels[i])] >= 0.01) {
            const indexML = mlOutput.findIndex(row => row[0] === benzodiazepineLabels[i]);
            const values = [mlOutput[indexML][2], mlOutput[indexML][3]];
            const max = Math.max(...values);
            const min = Math.min(...values);
            const difference = max / min;
            const mean = values.reduce((sum, value) => sum + value, 0) / values.length;
            const variance = values.reduce((sum, value) => sum + Math.pow(value - mean, 2), 0) / values.length;
            const standardDeviation = Math.sqrt(variance);
            passingBenzodiazepines.push({ label: benzodiazepineLabels[i], traceMLValue: e, standardDeviation, difference });
        }
    });
    if (passingBenzodiazepines.length > 0) {
        passingBenzodiazepines.sort((a, b) => a.traceMLValue - b.traceMLValue);
        return { hasTraceBenzodiazepine: true, traceBenzodiazepineLabel: passingBenzodiazepines[0].label }
    }
    return { hasTraceBenzodiazepine: false, traceBenzodiazepineLabel: null };

}

export const detectTraceXylazine = (label, processedSpectrum, result, mlOutput, xylazinePrediction) => {
    const approvedLabels = ['Caffeine', 'Dimethyl sulfone', 'Mannitol', 'Erythritol', 'Inositol', 'Sorbitol', 'Xylitol', 'Acetylfentanyl', 'Carfentanil', 'Despropionyl fentanyl', 'Fentanyl', 'Furanylfentanyl', 'Parafluorofentanyl', 'Remifentanil'];
    if (!approvedLabels.includes(label)) { return { hasTraceXylazine: false, traceXylazineLabel: null } };
    const xylazinePredictionClassification = xylazinePrediction;
    const passingXylazines = [];
    xylazinePredictionClassification.forEach((e, i) => {
        if (e >= 0.15) {
            const indexML = mlOutput.findIndex(row => row[0] === xylazineLabels[i]);
            const values = [mlOutput[indexML][2], mlOutput[indexML][3]];
            const max = Math.max(...values);
            const min = Math.min(...values);
            const difference = max / min;
            const mean = values.reduce((sum, value) => sum + value, 0) / values.length;
            const variance = values.reduce((sum, value) => sum + Math.pow(value - mean, 2), 0) / values.length;
            const standardDeviation = Math.sqrt(variance);
            let threshold = e >= 0.9 ? 0.015 : 0.02;
            if (min >= threshold) {
                passingXylazines.push({ label: xylazineLabels[i], traceMLValue: e, standardDeviation, difference });
            }
        }
    });
    if (passingXylazines.length > 0) {
        return { hasTraceXylazine: true, traceXylazineLabel: 'Xylazine' }
    }
    return { hasTraceXylazine: false, traceXylazineLabel: null };
}